import { PropsWithChildren } from "react";
import { LearnerPage } from "../Layouts/LearnerPage";

export const ProfilePage: React.FC<
  PropsWithChildren<{ withSidebar?: boolean }>
> = ({ withSidebar, children }) => (
  <LearnerPage
    title="Profile"
    {...(withSidebar ? {} : { sidebar: <></> })}
    dontTrackTime
    noPadding
  >
    <div className="flex flex-col items-center gap-6 px-4 py-6 sm:px-6">
      {children}
    </div>
  </LearnerPage>
);
