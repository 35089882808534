import { Learner } from "../types";

// Returns true if the learner has an active subscription
export function isSubscriptionActive(learner: Learner | null | undefined) {
  if (learner && learner.subscriptionExpiration) {
    const now = Date.now();
    const expires = new Date(learner.subscriptionExpiration).getTime();
    return now < expires;
  }
  return false;
}
