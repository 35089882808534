import { useRef, useEffect, useState, SetStateAction, Dispatch } from "react";
import { useMediaQuery, useWindowSize } from "usehooks-ts";
import { Course, myCourseDataType, myCoursesUnitDataType } from "../../types";
import clsx from "clsx";
import ProgressRadial from "../ProgressRadial";
import { CourseSearch } from "../Search/CourseSearch";
import { MOBILE_NAV_BAR_HEIGHT } from "../../constants";
import ReactTooltip from "react-tooltip";
import { CourseProgressRadial } from "./CourseProgressRadial";
import Button from "../../../student/components/generic/button";
import { generateStartCourseLink } from "../../utils/generateStartCourseLink";
import { useCourseContext } from "../../contexts/CourseContext";
import { useLearnerContext } from "../../contexts/LearnerContext";

type Props = {
  courseProgress: myCourseDataType | undefined;
  course: Course;
  iconUrl?: string;
  description?: string;
  setLastClickedId: Dispatch<SetStateAction<string>>;
};

const CourseSummaryHeader = (props: Props) => {
  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();
  const currentCourses = courseContext.getCurrentLearnerCourses(
    undefined,
    learnerContext
  );
  const hasCourseProgress = currentCourses.find(
    (myCourse) => myCourse.id === props.course.id
  );
  const startCourseLink = generateStartCourseLink(props.course);
  // last item in courseProgress is the courseTest info, so
  // subtract 1 from length to get number of units
  const sectionTotal = props.courseProgress?.units
    ? props.courseProgress?.units?.length - 1
    : 0;

  const isSmallDevice = useMediaQuery("(max-width : 1023px)");

  const courseProgressPercent = Math.round(
    (props.courseProgress?.progress || 0) * 100
  );

  const headerRef: any = useRef<HTMLDivElement>(null);
  const stickyRef: any = useRef<HTMLDivElement>(null);
  const legendRef: any = useRef<HTMLDivElement>(null);

  const [isSticky, setIsSticky] = useState(false);
  const [hideLegend, setHideLegend] = useState(false);
  const [legendHeight, setLegendHeight] = useState(
    legendRef.current?.offsetHeight
  );
  const { width: winWidth = 0 } = useWindowSize();
  ReactTooltip.rebuild();

  useEffect(() => {
    !isSticky && setLegendHeight(legendRef.current?.offsetHeight);
  }, [winWidth, isSticky]);

  const [stickyTopPosition, setStickyTopPosition] = useState(
    stickyRef.current &&
      Math.round(
        isSmallDevice
          ? stickyRef.current.offsetTop - MOBILE_NAV_BAR_HEIGHT
          : stickyRef.current.offsetTop
      )
  );

  useEffect(() => {
    const handleScroll = () => {
      const topPosition = Math.round(
        isSmallDevice
          ? stickyRef.current.offsetTop - MOBILE_NAV_BAR_HEIGHT
          : stickyRef.current.offsetTop
      );
      setStickyTopPosition(topPosition);

      const windowY = Math.round(window.scrollY);

      if (stickyRef.current && windowY > topPosition + 20) {
        setIsSticky(true);
      } else if (stickyRef.current && windowY < topPosition) {
        setIsSticky(false);
      }

      const legendPosition = Math.round(
        legendRef.current?.offsetTop + legendRef.current?.offsetHeight
      );

      if (legendRef.current) {
        if (windowY > topPosition + legendPosition + legendHeight) {
          setHideLegend(true);
        } else if (windowY < topPosition + legendPosition) {
          setHideLegend(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isSmallDevice, legendHeight]);

  const sectionInfoCopy = (
    <div className="mt-0.5 flex items-center gap-x-1 font-sans font-normal md:mt-2">
      <h3 className="text-sm leading-none">{`${sectionTotal} ${
        sectionTotal !== 1 ? "Units" : "Unit"
      }`}</h3>
      {hasCourseProgress && (
        <span className="rounded-full bg-dm-brand-blue-500 px-2 py-1 text-xs font-medium leading-none text-white">
          {`${courseProgressPercent}% Complete`}
        </span>
      )}
    </div>
  );

  return (
    <div
      ref={headerRef}
      className={clsx(
        "sticky z-[7] flex transform gap-x-6 border-dm-charcoal-100 bg-white px-6 py-4 pb-0.5 transition-all",
        isSticky
          ? "-mx-6 border-b shadow-[2px_2px_10px_0px_rgba(0,0,0,0.08)]"
          : "rounded-lg border"
      )}
      style={stickyTopPosition !== undefined ? { top: -stickyTopPosition } : {}}
    >
      <ReactTooltip
        id="unit-radial-tooltip"
        className="max-w-[14rem] font-sans text-sm"
        delayShow={250}
        delayHide={50}
        place="bottom"
        effect="solid"
      />

      <CourseProgressRadial
        courseProgress={props.courseProgress}
        iconUrl={props.iconUrl}
        className={clsx(
          "h-16 overflow-hidden transition-all max-md:hidden md:h-32",
          isSticky ? "w-0" : "w-16 md:w-32"
        )}
        imgClassName="md:h-28 md:w-28"
      />
      <div className="w-0 flex-grow">
        <header className="flex gap-x-2">
          <CourseProgressRadial
            courseProgress={props.courseProgress}
            iconUrl={props.iconUrl}
            className="h-12 w-12 transition-all md:hidden"
            imgClassName="md:h-28 md:w-28"
          />
          <div className="flex-grow">
            <h1 className="font-serif text-lg font-bold md:text-2xl">
              {props.courseProgress?.name}
            </h1>
            {sectionInfoCopy}
          </div>
          <div className="ml-3 hidden w-3/6 md:block">
            {!isSticky && <CourseSearch type="course" />}
          </div>
        </header>
        <p className="mt-4 text-base text-dm-charcoal-500">
          {props.description}
        </p>
        {!hasCourseProgress && startCourseLink && (
          <Button
            type="primary"
            size="small"
            className="mt-4 w-full"
            href={startCourseLink}
          >
            Start Course
          </Button>
        )}
        <div ref={stickyRef} className="mt-8 flex flex-col gap-y-4">
          <h2
            className={clsx(
              "font-serif font-bold transition-all",
              hideLegend ? "text-base" : "text-lg"
            )}
          >
            {isSticky && props.courseProgress?.name} Units
          </h2>
          <div className={clsx("flex gap-x-4 overflow-auto pb-1.5")}>
            {props.courseProgress?.units?.map(
              (unit: myCoursesUnitDataType, ix: number) => {
                return (
                  <div
                    data-for="unit-radial-tooltip"
                    data-tip={
                      unit.name === "course-test"
                        ? "Course Test"
                        : `Unit ${ix + 1}: ${unit.name}`
                    }
                    key={`unitProgress-${unit.id}-${ix}`}
                  >
                    <ProgressRadial
                      progressObject={unit.subunits}
                      name={unit.name}
                      id={unit.id}
                      progress={unit.progress}
                      index={ix}
                      isSticky={hideLegend}
                      setLastClickedId={props.setLastClickedId}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
        {/* Legend */}
        <div
          ref={legendRef}
          className={clsx(
            "flex flex-wrap gap-y-1 overflow-hidden transition-all md:gap-x-3 lg:flex-nowrap lg:gap-x-3 xl:gap-x-7",
            hideLegend ? "h-0" : "pb-8 pt-2"
          )}
          aria-hidden="true"
        >
          <div className="flex basis-1/2 items-center gap-x-1 text-xs leading-none sm:basis-1/3 md:basis-auto">
            <i
              className="far fa-check fa-fw text-xl leading-none !text-dm-brand-blue-500"
              aria-hidden="true"
            ></i>
            <span className="text-dm-gray-800">Unit Complete</span>
          </div>
          <div className="flex basis-1/2 items-center gap-x-1 text-xs leading-none sm:basis-1/3 md:basis-auto">
            <i
              className="fas fa-square fa-fw text-xl leading-none !text-dm-brand-blue-500"
              aria-hidden="true"
            ></i>
            <span className="text-dm-gray-800">Section Complete</span>
          </div>
          <div className="flex basis-1/2 items-center gap-x-1 text-xs leading-none sm:basis-1/3 md:basis-auto">
            <i
              className="fas fa-square fa-fw text-xl leading-none !text-dm-brand-blue-200"
              aria-hidden="true"
            ></i>
            <span className="text-dm-gray-800">Section In Progress</span>
          </div>
          <div className="flex basis-1/2 items-center gap-x-1 text-xs leading-none sm:basis-1/3 md:basis-auto">
            <i
              className="fas fa-square fa-fw text-xl leading-none !text-dm-charcoal-100"
              aria-hidden="true"
            ></i>
            <span className="text-dm-gray-800">Not Started</span>
          </div>
          <div className="flex basis-1/2 items-center gap-x-1 text-xs leading-none sm:basis-1/3 md:basis-auto">
            <i
              className="far fa-list fa-fw text-xl leading-none !text-dm-gray-200"
              aria-hidden="true"
            ></i>
            <span className="text-dm-gray-800">Course Test</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSummaryHeader;
