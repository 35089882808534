import { format } from "date-fns";
import { useParentContext } from "../../../contexts/ParentContext";
import { CancelStepHeader } from "./CancelStepHeader";
import { useCancellationContext } from "./CancellationContext";
import { oxfordCommaJoin } from "../../../utils/oxfordCommaJoin";
import { useLearnerContext } from "../../../contexts/LearnerContext";

export const CancelConfirmation: React.FC = () => {
  const { selectedLearners } = useCancellationContext();
  const { learner: accountOwner } = useLearnerContext();
  const { learners } = useParentContext();
  const [learner] = learners;

  const isCancelingOneLearner = selectedLearners.length === 1;
  const isPartialCancel = selectedLearners.length < learners.length;
  const showNamesInHeader = isPartialCancel || !isCancelingOneLearner;

  const selectedLearnerNames = oxfordCommaJoin(
    selectedLearners.map((id) => {
      const l = learners.find((l) => l._id === id);
      return `${l?.first} ${l?.last}`;
    })
  );

  const periodEnd = format(
    new Date(
      learner
        ? learner.subscriptionExpiration
        : accountOwner?.subscriptionExpiration || "0"
    ),
    "MMMM dd, yyyy"
  );

  return (
    <div className="flex flex-col gap-4 sm:gap-6">
      <CancelStepHeader
        title={
          showNamesInHeader
            ? "Subscription canceled for:"
            : "Your Subscription is Canceled"
        }
      />
      {showNamesInHeader && (
        <h3 className="text-center font-serif text-lg font-bold">
          {selectedLearnerNames}
        </h3>
      )}
      <div className="flex flex-col gap-2">
        {showNamesInHeader ? (
          <p>
            Account access will be disabled at the end of your current billing
            period on {periodEnd}.
          </p>
        ) : (
          <p>
            {selectedLearnerNames} will lose access to DeltaMath for Home after{" "}
            {periodEnd}.
          </p>
        )}
        <p>
          If you change your mind, you can reactivate this subscription anytime.
          We hope to see you back soon!
        </p>
      </div>
    </div>
  );
};
