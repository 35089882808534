import { Progress, Course } from "../../types";
import IconAssignmentProgress from "../IconAssignmentProgress";
import Button from "../../../student/components/generic/button";
import AssignmentPreviewModal from "../Modals/AssignmentPreviewModal";
import { useState } from "react";
import TestConfirmationModal from "../Modals/TestConfirmationModal";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useNavigate } from "react-router-dom";
import { sum, clamp, findIndex } from "lodash";
import { PROGRESS_MINIMUM } from "../../constants";
import { TooltipButton } from "../../../shared/TooltipButton";
import { LearnerAssignmentSkillData } from "../../types";
import clsx from "clsx";
import { REACT_APP_LEARNER_LINK } from "../../../utils";

type Props = {
  courseData: Course;
  progress: Progress | undefined;
  coursePath?: string;
  estimatedTime: number;
  isFirstAttemptComplete: boolean;
  isInProgress: boolean;
};

const CourseTestCard = (props: Props) => {
  const learnerContext = useLearnerContext();
  const navigate = useNavigate();
  const baseUrl = `${REACT_APP_LEARNER_LINK}/${props.coursePath}/coursetest`;
  const [showAssignmentModal, setShowAssignmentModal] =
    useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const allSubunitsProgress =
    props.progress?.units && props.progress.units.length > 0
      ? sum(props.progress.units.map((x) => x.progress)) /
        props.progress.units.length
      : 0;

  const progress =
    (!!props.progress?.courseTest && props.progress?.courseTest?.progress === 0
      ? 0.01
      : props.progress?.courseTest?.progress || 0) * 100;
  const maxGrade = Math.round(
    (props.progress?.courseTest?.maxGrade || 0) * 100
  );

  const buttonText = props.progress?.courseTest?.submitted
    ? "Review Course Test"
    : props.progress?.courseTest
    ? "Resume Course Test"
    : "Start Course Test";

  const initializeProgressForCourse = async () => {
    navigate(baseUrl);
  };

  const startTest = () => {
    if (allSubunitsProgress < PROGRESS_MINIMUM) {
      setShowConfirmationModal(true);
    } else {
      initializeProgressForCourse();
    }
  };

  const learnerDifficulty = learnerContext.getLearnerLevel();
  const flatSubunitProgress =
    props.progress?.units?.flatMap((u) => u.subunits) || [];

  const skillsInCourse = (props.courseData?.units || []).flatMap((unit) =>
    unit?.subunits
      ?.flatMap((su) => su.skills)
      .filter((sk) => {
        const subunitProgress = flatSubunitProgress.find(
          (su) => su.subunitId === sk.subunitId
        );

        return (
          sk.difficulty ===
            (subunitProgress?.difficulty || learnerDifficulty) ||
          sk.difficulty === "both"
        );
      })
  ).length;

  const skillNumbers =
    (props.progress?.courseTest?.assignment?.skills || []).length ||
    (skillsInCourse <= 30 ? skillsInCourse : 30);
  const skillsCompleted = (
    props.progress?.courseTest?.assignment?.skills || []
  ).filter((x) => x.skillComplete).length;

  const firstUnsolved = clamp(
    findIndex(
      props.progress?.courseTest?.assignment?.skills || [],
      (skill: LearnerAssignmentSkillData) => !skill.skillComplete
    ),
    0,
    (props.progress?.courseTest?.assignment?.skills?.length || 1) - 1
  );
  const primaryButtonUrl = props.progress?.courseTest
    ? props.isInProgress
      ? `${baseUrl}/${firstUnsolved}`
      : baseUrl
    : undefined;
  const courseTestButtons = (
    <>
      {props.progress?.courseTest?.submitted && (
        <Button
          href={`${baseUrl}?retake=true`}
          type="outline"
          size="small"
          className="max-md:basis-full"
        >
          Retake Course Test
        </Button>
      )}
      <Button
        onClick={!props.progress?.courseTest ? startTest : undefined}
        href={primaryButtonUrl}
        type={
          props.progress?.courseTest || allSubunitsProgress >= PROGRESS_MINIMUM
            ? "primary"
            : "outline"
        }
        size="small"
        className="max-md:basis-full"
      >
        {buttonText}
      </Button>
    </>
  );
  const currDuration = props.progress?.courseTest?.assignment?.duration;
  const bestScoreTime = !props.isFirstAttemptComplete
    ? currDuration
    : props.progress?.courseTest?.assignment?.resultHist
        ?.filter(
          (attempt) =>
            attempt.duration !== undefined &&
            attempt.grade === props.progress?.courseTest?.maxGrade
        )
        .reduce(
          (acc, curr) =>
            acc < (curr.duration ?? 0) ? acc : curr.duration ?? 0,
          0
        ) || currDuration;

  const duration = bestScoreTime
    ? Math.max(1, Math.round(bestScoreTime / 60))
    : undefined;

  return (
    <div
      id={`panel-course-test-${props.courseData.id}`}
      className="w-full rounded-lg border border-dm-charcoal-100 bg-white px-6 py-4"
    >
      <div className="flex items-center justify-between gap-x-2">
        <div className="flex basis-full items-center gap-2">
          <IconAssignmentProgress
            type={"courseTest"}
            progress={progress}
            submitted={props.progress?.courseTest?.submitted !== undefined}
            maxGrade={maxGrade}
          />
          <h3 className="text-lg font-bold">
            <span className={clsx(!props.progress?.courseTest && "mr-2")}>
              {props.courseData.name} Test
              {props.isInProgress && ": In Progress"}
            </span>
            {!props.progress?.courseTest && (
              <TooltipButton
                message="Preview Test Questions"
                onClick={() => setShowAssignmentModal(true)}
              >
                <i className="far fa-eye text-dm-gray-200" aria-hidden="true" />
              </TooltipButton>
            )}
          </h3>
        </div>
        <div className="flex flex-shrink-0 basis-full gap-4 max-md:hidden md:flex-shrink-0 md:basis-auto">
          {courseTestButtons}
        </div>
      </div>
      <div className="my-4 flex gap-2 text-sm">
        {!props.isInProgress && !props.isFirstAttemptComplete ? (
          <>
            <p>{skillNumbers} Questions</p>
            {" | "}
            <p>{props.estimatedTime} Minutes (estimated)</p>
          </>
        ) : props.isInProgress ? (
          <>
            <p>
              {skillsCompleted}/{skillNumbers} Questions Answered
            </p>
          </>
        ) : (
          <>
            <p>Score: {maxGrade}%</p>
            {duration && (
              <>
                {" | "}
                <p>
                  {duration}
                  {duration === 1 ? " Minute" : " Minutes"} Spent
                </p>
              </>
            )}
          </>
        )}
      </div>
      <div className="flex basis-full flex-wrap gap-2 md:hidden">
        {courseTestButtons}
      </div>
      <AssignmentPreviewModal
        title="Course Test Preview"
        courseId={props.courseData.id}
        type="courseTest"
        visible={showAssignmentModal}
        toggleVisible={setShowAssignmentModal}
        navigateButtonText="Go To Course Test"
        navigateHref={baseUrl}
      />
      <TestConfirmationModal
        visible={showConfirmationModal}
        toggleVisible={setShowConfirmationModal}
        onConfirm={initializeProgressForCourse}
        confirmButtonText="Start Course Test"
        closeButtonText="Back To Course Units"
        type="courseTest"
      />
    </div>
  );
};

export default CourseTestCard;
