import { Link, NavLink } from "react-router-dom";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../utils";
import { ResponsiveImage } from "../ResponsiveImage";
import { useUserContext } from "../../../shared/contexts/UserContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { UserWarningIcon } from "./UserWarningIcon";
import { useParentContext } from "../../contexts/ParentContext";

export const ParentNav: React.FC = () => {
  const userContext = useUserContext();
  const learnerContext = useLearnerContext();
  const { learners } = useParentContext();

  return (
    <nav className="sticky inset-x-0 top-0 z-20 flex items-center justify-between gap-x-5 border-b border-dm-charcoal-100 bg-white px-4 py-3">
      <Link
        to={`${REACT_APP_LEARNER_LINK}/parent`}
        className="h-9 bg-white focus:outline-none"
      >
        <ResponsiveImage
          className="z-2 transition-width h-9 duration-300 ease-out"
          srcs={[getFilePath("/images/DeltaMath-Logo_Home.svg")]}
          alt="DeltaMath Home"
        />
      </Link>
      <div className="flex items-center gap-6">
        <NavLink
          className="flex items-center gap-2 rounded-[4px] border border-dm-brand-blue-100 bg-dm-brand-blue-100 px-4 py-2 hover:border-dm-brand-blue-200"
          to={`${REACT_APP_LEARNER_LINK}/parent/profile`}
        >
          <span className="relative">
            {(learnerContext.learner?.subscriptionQuantity ?? 0) >
            learners.length ? (
              <UserWarningIcon maskClassName="fill-dm-brand-blue-100" />
            ) : (
              <i className="far fa-user" aria-hidden />
            )}
          </span>
          {learnerContext.learner ? (
            <span>
              {learnerContext.learner?.first} {learnerContext.learner?.last}
            </span>
          ) : (
            <span className="text-transparent">Loading user info...</span>
          )}
        </NavLink>
        {userContext.state.decodedJwt?.data?.decampTarget === undefined && (
          <button
            className="align-middle text-sm text-dm-brand-blue-500 hover:text-dm-brand-blue-600 active:text-dm-brand-blue-800 disabled:opacity-70"
            onClick={() => {
              learnerContext.logoutLearner();
              window.location.href = `${REACT_APP_LEARNER_LINK}/sign-in`;
            }}
            aria-label="log out"
          >
            Log Out
          </button>
        )}
      </div>
    </nav>
  );
};
