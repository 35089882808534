import { useEffect } from "react";
import { LearnerPage } from "./Layouts/LearnerPage";
import { useLearnerContext } from "../contexts/LearnerContext";
import { useNavigate } from "react-router-dom";
import { REACT_APP_LEARNER_LINK } from "../../utils";
import { isSubscriptionActive } from "../utils/isSubscriptionActive";

export const SubscriptionExpiredPage: React.FC = () => {
  const { learner } = useLearnerContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSubscriptionActive(learner)) {
      navigate(`${REACT_APP_LEARNER_LINK}/dashboard`);
    }
  }, [learner, navigate]);

  return (
    <LearnerPage title="Subscription expired">
      <div className="flex h-full w-full flex-col justify-center gap-2">
        <h1 className="text-center text-lg font-bold">
          Oh no! You no longer have access to DeltaMath for Home
        </h1>
        <p className="text-center">
          Ask your parent to renew your subscription
        </p>
      </div>
    </LearnerPage>
  );
};
