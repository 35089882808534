import { format } from "date-fns";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useParentContext } from "../../contexts/ParentContext";
import { AddLearnerButton } from "./AddLearnerButton";
import { getLearnerAccountStatus } from "./getLearnerAccountStatus";
import { SubscriptionStatusPill } from "./SubscriptionStatusPill";
import { PropsWithChildren } from "react";
import { Learner, LearnerSubscriptionDetails } from "../../types";
import clsx from "clsx";
import { EditAccountButton } from "./EditAccountButton";
import { ReactivateLink } from "./ReactivateLink";
import { ResendInviteLink } from "./ResendInviteLink";

const TableNarrowField: React.FC<PropsWithChildren<{ label: string }>> = ({
  label,
  children,
}) => (
  <div className="flex flex-col gap-2">
    <h4 className="font-bold">{label}</h4>
    <div>{children}</div>
  </div>
);

const TableNarrowRow: React.FC<
  PropsWithChildren<{
    learner: Learner;
    subscription: LearnerSubscriptionDetails;
    withBorder?: boolean;
  }>
> = ({ learner, subscription, withBorder = false }) => {
  const { learner: parent } = useLearnerContext();
  const status = getLearnerAccountStatus(learner);
  const parentStatus = parent ? getLearnerAccountStatus(parent) : null;
  return (
    <div
      className={clsx(
        "relative flex flex-col gap-4",
        withBorder && "border-t border-dm-charcoal-100 pt-8"
      )}
    >
      <EditAccountButton
        user={learner}
        className={clsx(
          "absolute right-0 text-lg",
          withBorder ? "top-5" : "-top-3"
        )}
      />
      <TableNarrowField label="Name">
        {learner.first} {learner?.last}
      </TableNarrowField>
      <TableNarrowField label="Account Type">
        {learner.entitlements.includes("parent") ? "Parent" : "Learner"}
      </TableNarrowField>
      <TableNarrowField label="User Name/Email">
        {learner.email}
      </TableNarrowField>
      <TableNarrowField label="Password">{"*".repeat(16)}</TableNarrowField>
      <TableNarrowField label="Status">
        {<SubscriptionStatusPill status={status} />}
      </TableNarrowField>
      <TableNarrowField label="Learner Start Date">
        {status === "pending" ? (
          <ResendInviteLink learnerId={learner._id} />
        ) : status === "canceled" && parentStatus !== "canceled" ? (
          <ReactivateLink subscription={subscription} learner={learner} />
        ) : (
          format(new Date(learner.createdAt), "MM/dd/yy")
        )}
      </TableNarrowField>
    </div>
  );
};

export const AccountsTableNarrow: React.FC<{
  subscription: LearnerSubscriptionDetails;
}> = ({ subscription }) => {
  const { learner: parent } = useLearnerContext();
  const { learners } = useParentContext();

  return (
    <div className="flex w-full flex-col gap-6 text-sm lg:hidden">
      {parent && (
        <TableNarrowRow subscription={subscription} learner={parent} />
      )}
      {learners.map((learner) => (
        <TableNarrowRow
          key={learner._id}
          subscription={subscription}
          learner={learner}
          withBorder
        />
      ))}
      <AddLearnerButton
        className="mt-8 flex h-10 items-center justify-center gap-2 p-0"
        subscription={subscription}
      />
    </div>
  );
};
