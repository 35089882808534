import { format } from "date-fns";
import { SkeletonText } from "../../Skeleton";
import { ProfileField } from "../ProfileField";
import { useState } from "react";
import Modal from "../../../../student/components/generic/Modal";
import { useDMQuery } from "../../../../utils";
import { LearnerSubscriptionBillingHistoryItem } from "../../../types";
import { formatPrice } from "../../../utils/formatPrice";
import { capitalize } from "lodash";
import { DmLoadingSpinner } from "../../../../manager/utils/functions";

export const BillingHistoryField: React.FC<{
  periodStart: string | undefined;
}> = ({ periodStart }) => {
  const [showHistory, setShowHistory] = useState(false);

  const {
    data: billingHistoryData,
    isLoading,
    refetch: fetchBillingHistory,
  } = useDMQuery<{
    history: LearnerSubscriptionBillingHistoryItem[];
  }>({
    path: "/payments/subscriptions/history",
    queryOptions: { enabled: false },
  });

  return (
    <>
      <ProfileField label="Billing History">
        {periodStart ? (
          <>
            <span>
              Last payment on:{" "}
              {periodStart
                ? format(new Date(periodStart), "MM/dd/yy")
                : "loading date"}
            </span>
            <button
              className="text-dm-brand-blue-500 underline-offset-2 hover:underline"
              onClick={() => {
                setShowHistory(true);
                fetchBillingHistory();
              }}
            >
              View History
            </button>
          </>
        ) : (
          <SkeletonText>Loading billing data</SkeletonText>
        )}
      </ProfileField>

      <Modal
        visible={showHistory}
        onClose={() => setShowHistory(false)}
        closeX
        body={
          <div>
            <h2 className="mb-6 text-center font-serif text-2xl font-bold text-dm-brand-blue-600">
              Billing History
            </h2>
            {isLoading ? (
              <div className="flex h-48 w-full items-center justify-center">
                <DmLoadingSpinner message="" />
              </div>
            ) : (
              <table className="w-full table-auto border-separate border-spacing-y-4 px-8 text-sm">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Activity</th>
                    <th>Total</th>
                    <th>Payment Method</th>
                  </tr>
                </thead>
                <tbody>
                  {billingHistoryData?.history.map((item, index) => (
                    <tr key={item.date}>
                      <td>{format(new Date(item.date), "MM/dd/yy")}</td>
                      <td>Charge</td>
                      <td>{formatPrice(item.amount)}</td>
                      <td>
                        {capitalize(item.card.brand)}: **** **** ****{" "}
                        {item.card.last4}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        }
      />
    </>
  );
};
