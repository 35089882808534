import { format } from "date-fns";
import { ProfilePage } from "./ProfilePage";
import { ProfileSection } from "./ProfileSection";
import { ProfileField } from "./ProfileField";
import { ProfileFieldGroup } from "./ProfileFieldGroup";
import { Learner, LearnerSubscriptionDetails } from "../../types";
import { useDMQuery } from "../../../utils";
import { ProfileColumn } from "./ProfileColumn";
import { SubscriptionDetailsField } from "./Fields/SubscriptionDetailsField";
import { BillingFrequencyField } from "./Fields/BillingFrequencyField";
import { BillingHistoryField } from "./Fields/BillingHistoryField";
import { PaymentInformationField } from "./Fields/PaymentInformationField";
import { UpcomingChargesField } from "./Fields/UpcomingChargesField";
import { EditAccountButton } from "./EditAccountButton";
import { CancellationContextProvider } from "./Cancellation/CancellationContext";
import { CancelSubscriptionModal } from "./Cancellation/CancelSubscriptionModal";

export const IndependentLearnerProfile: React.FC<{ user: Learner }> = ({
  user,
}) => {
  const { data: subscriptionData, refetch: refetchSubscriptionData } =
    useDMQuery<{
      subscription: LearnerSubscriptionDetails;
    }>({
      path: "/payments/subscriptions/active",
      queryOptions: { refetchOnWindowFocus: false },
    });

  return (
    <ProfilePage withSidebar>
      <CancellationContextProvider
        refetchSubscriptionData={refetchSubscriptionData}
      >
        <ProfileSection title="Account & Subscription Information">
          <ProfileColumn>
            <ProfileFieldGroup title="Your Account" className="relative">
              <EditAccountButton
                className="absolute right-0 top-0 sm:right-10"
                user={user}
              />
              <ProfileField label="Account Holder Name">
                {user.first} {user.last}
              </ProfileField>
              <ProfileField label="Account Holder Email">
                {user.email}
              </ProfileField>
              <ProfileField label="Password">{"*".repeat(16)}</ProfileField>
              <ProfileField label="Learner Start Date">
                {format(new Date(user.createdAt), "MMMM' 'd', 'yyyy")}
              </ProfileField>
            </ProfileFieldGroup>

            <ProfileFieldGroup title="Your Subscription">
              <SubscriptionDetailsField
                subscription={subscriptionData?.subscription}
              />
            </ProfileFieldGroup>
          </ProfileColumn>

          <ProfileColumn>
            <ProfileFieldGroup title="Billing">
              <UpcomingChargesField
                subscription={subscriptionData?.subscription}
              />

              <BillingFrequencyField
                subscription={subscriptionData?.subscription}
              />

              {subscriptionData?.subscription.status !== "trialing" && (
                <BillingHistoryField
                  periodStart={subscriptionData?.subscription.periodStart}
                />
              )}

              <PaymentInformationField
                card={subscriptionData?.subscription.card}
              />
            </ProfileFieldGroup>
          </ProfileColumn>
        </ProfileSection>
        <CancelSubscriptionModal />
      </CancellationContextProvider>
    </ProfilePage>
  );
};
