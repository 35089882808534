import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useCourseContext } from "../contexts/CourseContext";
import { useLearnerContext } from "../contexts/LearnerContext";
import { Course } from "../types";
import CourseSummaryHeader from "./Course/CourseSummaryHeader";
import UnitSummaryCard from "./Unit/UnitSummaryCard";
import { CourseSearch } from "./Search/CourseSearch";
import CourseTestCard from "./Course/CourseTestCard";
import { CourseLandingWrapper } from "./CourseLandingWrapper";
import { CourseLandingLoading } from "./CourseLandingLoading";
import { LandingPageError } from "./LandingPageError";
import { LearnerPage } from "./Layouts/LearnerPage";
import { useCurrentCourseData } from "../utils/useCurrentCourseData";

const CourseLanding = () => {
  const { coursePath } = useParams();
  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();
  const courseData = courseContext.getCourseData(coursePath);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [coursePath]);

  useEffect(() => {
    courseContext.setActiveCourse(courseData?.id);
  }, [courseContext, courseData]);

  return (
    <LearnerPage title={courseData?.name}>
      {courseContext.state.isLoadingCourse ||
      courseContext.state.isLoadingSubunits ||
      learnerContext.state.isLoadingProgress ? (
        <CourseLandingLoading />
      ) : courseData ? (
        <CourseLandingWithData courseData={courseData} />
      ) : (
        <LandingPageError>Unable to load this course</LandingPageError>
      )}
    </LearnerPage>
  );
};

const CourseLandingWithData: React.FC<{ courseData: Course }> = ({
  courseData,
}) => {
  const { coursePath } = useParams();
  const learnerContext = useLearnerContext();
  const courseProgress = learnerContext.getProgress(courseData?.id || "");
  const { isFirstAttemptComplete, isInProgress, currentCourseData } =
    useCurrentCourseData(courseData?.id);

  const [lastClickedId, setLastClickedId] = useState("");

  ReactTooltip.rebuild();

  const estimatedTime = Math.max(
    5,
    Math.ceil((courseData?.avgQuizTime || 0) / 60 / 5) * 5
  );

  return (
    <CourseLandingWrapper
      mobileCourseSearch={<CourseSearch type="course" />}
      header={
        <CourseSummaryHeader
          courseProgress={currentCourseData}
          course={courseData}
          iconUrl={courseData.iconUrl}
          description={courseData.description}
          setLastClickedId={setLastClickedId}
        />
      }
    >
      {courseData.unitOrder.map((u, i) => {
        const unit = (courseData.units ?? []).find((unit) => unit.id === u);
        if (!unit || unit?.subunitOrder.length <= 0) {
          return null;
        }

        return (
          <UnitSummaryCard
            unit={unit}
            course={courseData}
            key={`unitcard-${unit.id}`}
            cardId={`panel-${unit.id}`}
            unitNumber={i + 1}
            lastClickedId={lastClickedId}
          />
        );
      })}

      <h3 className="mb-2 mt-6 font-serif text-lg font-bold">Course Test</h3>
      <CourseTestCard
        courseData={courseData}
        coursePath={coursePath}
        progress={courseProgress}
        estimatedTime={estimatedTime}
        isFirstAttemptComplete={isFirstAttemptComplete}
        isInProgress={isInProgress}
      />
      <ReactTooltip
        id="sidebar-tooltip"
        className="font-sans text-xs"
        effect="solid"
        delayShow={800}
        delayHide={50}
        place="right"
      />
    </CourseLandingWrapper>
  );
};

export default CourseLanding;
