import { Dialog, Transition } from "@headlessui/react";
import {
  useContext,
  Fragment,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from "react";
import { debounce } from "lodash";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { useDMQuery } from "../../utils";
import {
  checkForCustomFiles,
  generateProblemScripts,
  processlines,
  resizeKatex,
} from "../utils";
import Problem from "./standard-problems/Problem";
import renderMathInElement from "../utils/auto-render";
import { XIcon, ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { uniqBy, cloneDeep, toArray } from "lodash";
import clsx from "clsx";
import { getCustomPromptA11y } from "../../shared/accessibility/a11yFunctions";
import { PAGE_TYPE_ID_MAP } from "../../shared/constants";

type Props = {
  taId?: string;
  skillCode?: string;
  isShowing?: boolean;
  setIsShowing: Dispatch<SetStateAction<any>>;
  skillName: string;
  typesSelected?: any;
};

let customPromptA11y: undefined | JSX.Element = undefined;

export default function ExamplesModal({
  taId,
  skillCode,
  isShowing,
  setIsShowing,
  skillName,
  typesSelected,
}: Props): JSX.Element {
  const [exampleData, setExampleData] = useState<any>({}); // skillname: {numExamples, currentExampleIndex, problems[], range:{min, max}}

  const { setLoadingData, customExternalFiles } = useContext(
    StudentSectionsContext
  );

  const types: any =
    typesSelected !== undefined
      ? Object.keys(typesSelected)
          .map((key) => `${key}=${typesSelected[key]}`)
          .join("-")
      : undefined;

  const skillAndType = `${skillName}${types ? "-" + types : ""}`;
  const exampleRange = {
    min: exampleData?.[skillAndType]?.range?.min || 0,
    max: exampleData?.[skillAndType]?.range?.max || 5,
  };
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const maxExamples = exampleData?.[skillAndType]?.numExamples || null;
  const currentExampleIndex =
    exampleData?.[skillAndType]?.currentExampleIndex || 0;
  const currentExample =
    exampleData?.[skillAndType]?.problems?.[currentExampleIndex];
  const mathBlockContent = useRef<null | HTMLDivElement>(null);
  const loadingContent = useRef<null | HTMLDivElement>(null);
  const [katexResizingData, setKatexResizingData] = useState<any>({});
  const [customDataFetched, setCustomDataFetched] = useState<boolean>(false);

  const isCustom =
    currentExample?.ansType === "custom" ||
    currentExample?.data?.externalUrlExists ||
    currentExample?.data?.sharedExternalFile;

  window.renderMathInElement = renderMathInElement;

  const pageID = PAGE_TYPE_ID_MAP["modal"];

  /* ****************** */
  /* exampleProblem */
  /* ****************** */

  const { refetch: sampleRefetch } = useDMQuery({
    path: `/manager_new/skill-codes/sample_problem?sk=${skillCode}`,
    customerServiceHeader: true,
    queryOptions: {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: async (data: any) => {
        await sortExamples(data);
        if (exampleRange.max < data.numExamples) {
          setExampleData((prevState: any) => ({
            ...prevState,
            [skillAndType]: {
              ...prevState[skillAndType],
              range: {
                min: prevState[skillAndType]?.range?.max,
                max: data.numExamples,
              },
            },
          }));
        }

        // setLoadingData((prevState: any) => ({
        //   ...prevState,
        //   isShowing: false,
        // }));
      },
      onError: (err: Error) => {
        console.log("ERROR", err);
      },
    },
  });

  const { refetch: examplesRefetch, isFetching } = useDMQuery({
    path: `/student/exampleProblem/${taId}`,
    method: "post",
    cacheKey: [
      "exampleProblem",
      skillName,
      `min:${exampleRange?.min},max:${exampleRange?.max}`,
    ],
    requestBody: {
      sk: skillName,
      ...(typesSelected !== undefined
        ? { typesSelected: typesSelected }
        : null),
      range: [exampleRange?.min, exampleRange?.max],
    },
    queryOptions: {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      enabled: false,
      onSuccess: async (data: any) => {
        await sortExamples(data);
        if (exampleRange.max < data.numExamples) {
          setExampleData((prevState: any) => ({
            ...prevState,
            [skillAndType]: {
              ...prevState[skillAndType],
              range: {
                min: prevState[skillAndType]?.range?.max,
                max: data.numExamples,
              },
            },
          }));
        }

        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: false,
        }));
      },
      onError: (error: any, variables: any, context: any) => {
        console.group("example onError");
        console.log("error", error?.response?.data?.message || error?.error);
        console.log("variables", variables);
        console.log("context", context);
        console.groupEnd();
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: "Error",
          message: `${
            error?.response?.data?.message ||
            error?.message ||
            error?.error ||
            ""
          }`,
        }));
      },
    },
  });

  // fetch initial or additional examples
  useEffect(() => {
    if (skillName && isShowing && currentExample === undefined && !skillCode) {
      loadingContent?.current?.classList.remove("hidden");
      examplesRefetch();
    } else if (
      skillName &&
      isShowing &&
      currentExample === undefined &&
      skillCode
    ) {
      sampleRefetch();
    }
  }, [currentExample, skillName, isShowing, skillCode]);

  // useEffect to invoke displayQuestion after custom data is fetched (for custom problems only)
  useEffect(() => {
    if (isCustom && customDataFetched) displayQuestion();
  }, [customDataFetched]);

  useEffect(() => {
    if (currentExample === undefined || !modalOpen) return;

    mathBlockContent?.current?.classList.add("invisible");

    setKatexResizingData({});

    if (isCustom) {
      // fetch custom data and update problem object with problemScripts
      setCustomFileData(currentExample);
    } else {
      // display the question immediately
      displayQuestion();
    }

    const exampleBlock = document.getElementById("mathBlockExample");

    if (exampleBlock) renderMathInElement(exampleBlock);

    setKatexResizingData((prevState: any) => ({
      ...prevState,
      ["solution"]: resizeKatex("modal", prevState),
    }));
  }, [currentExample?._id, modalOpen]); //isShowing]);

  const setCustomFileData = async (problem: any) => {
    /* TODO: reinstate cache!  
      NOTE: Originally cached custom files by skillcode, 
      but each individual problem can potentially require a different custom file.
      Attempted to cache by file url, 
      but it led to a race condition when calling the inlineSolutionScripts.
      Needs another attempt!
    */
    // fetch the files and set the ref, if the correct files are not already there
    // if (isCustom && !customExternalFiles.current.has(problem.skillcode)) {
    //   const files = await checkForCustomFiles(problem);
    //   customExternalFiles.current.set(problem.skillcode, files);
    // }
    // const customData = isCustom
    //   ? generateProblemScripts(
    //       customExternalFiles.current.get(problem.skillcode),
    //       problem
    //     )
    //   : problem;

    const customData = generateProblemScripts(
      await checkForCustomFiles(problem), // customExternalFiles.current.get(problem.skillcode),
      problem
    );

    setCustomDataFetched(true);

    setExampleData((prevState: any) => ({
      ...prevState,
      [skillAndType]: {
        ...prevState[skillAndType],
        problems: toArray({
          ...prevState[skillAndType]["problems"],
          [currentExampleIndex]: {
            ...prevState[skillAndType]["problems"][
              prevState[skillAndType]?.currentExampleIndex
            ],
            ...cloneDeep(customData),
          },
        }),
      },
    }));
  };

  useEffect(() => {
    if (skillName === undefined) return;
    const timer = setTimeout(() => {
      document.querySelector(`#${pageID} .answerData`)?.classList.add("hidden");
      loadingContent?.current?.classList.add("hidden");
      mathBlockContent?.current?.classList.remove("invisible");
      clearTimeout(timer);
    }, 0);

    return () => clearTimeout(timer);
  }, [katexResizingData]);

  const inlineSolutionCode = () => {
    return `
      window.deltaGraphs = [];
      ${currentExample?.inlineSolutionCode};`;
  };

  const displayQuestion = () => {
    // These are possibly used by inlineSolutionCode when eval()
    // Guarantees data & page are in scope.
    // !!DO NOT DELETE!!

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data = currentExample?.data?.data || {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const page = document.getElementById(pageID);

    if (currentExample?.data?.inlineFirst === undefined) {
      eval(inlineSolutionCode());
      if (currentExample?.problemScripts && currentExample?.lines) {
        currentExample?.problemScripts?.solutionScripts(page);
      }
    } else if (currentExample?.data?.inlineFirst === false) {
      if (currentExample?.problemScripts && currentExample?.lines) {
        currentExample?.problemScripts?.solutionScripts(page);
      }
      eval(inlineSolutionCode());
    }
    // increase input width as more data is typed in
    if (
      document.querySelector(
        'script[src*="/assets/jquery.auto-grow-input.min.js"]'
      ) &&
      (window as any).$?.fn?.autoGrowInput
    ) {
      eval(`$('.display-problem input:not(.no-auto-grow)').autoGrowInput();`);
    }
    //[MB - 1.26.2024] - allowing for module coder over-ride of accesibility text for the prompt
    if (
      typeof currentExample?.promptA11ySettings?.customString !== "undefined"
    ) {
      customPromptA11y = getCustomPromptA11y(
        currentExample.prompt,
        currentExample.promptA11ySettings
      );
    } else {
      customPromptA11y = undefined;
    }
  };

  // for every call to the examples endpoint, add the new problems to the array for that skillName
  const sortExamples = async (data: any) => {
    setExampleData((prevState: any) => ({
      ...prevState,
      [skillAndType]: {
        ...prevState[skillAndType],
        numExamples: data.numExamples,
        currentExampleIndex: prevState[skillAndType]?.currentExampleIndex || 0,
        range: {
          min: prevState[skillAndType]?.range?.min || 0,
          max: prevState[skillAndType]?.range?.max || 5,
          // min: (exampleRange.max < data.numExamples ? exampleRange.max : exampleRange.min),
          // max: (exampleRange.max < data.numExamples ? data.numExamples : exampleRange.max),
        },
        problems: uniqBy(
          [
            ...(prevState[skillAndType]?.problems
              ? toArray(prevState[skillAndType].problems)
              : []),
            ...toArray(data.problems),
          ],
          "_id"
        ),
      },
    }));
  };

  // Handler for "Previous/Next Example" buttons
  const anotherExample = (next: boolean) => {
    if (isFetching) return;
    mathBlockContent?.current?.classList.add("invisible");
    const increment = next
      ? Math.min(currentExampleIndex + 1, maxExamples - 1)
      : Math.max(currentExampleIndex - 1, 0);
    setExampleData((prevState: any) => ({
      ...prevState,
      [skillAndType]: {
        ...prevState[skillAndType],
        currentExampleIndex: increment,
      },
    }));
    setCustomDataFetched(false);
  };

  /* On browser resize, call resizeKatex() to readjust the KaTeX width */
  const handleResize = () => {
    setKatexResizingData((prevState: any) => ({
      ...prevState,
      ["solution"]: resizeKatex("modal", prevState),
    }));
  };

  const debounce_resize = debounce(handleResize, 150);

  useEffect(() => {
    window.addEventListener("resize", debounce_resize);
    return () => {
      window.removeEventListener("resize", debounce_resize);
    };
  });

  return (
    <Transition
      show={isShowing}
      as={Fragment}
      beforeEnter={() => {
        setModalOpen(false);
      }}
      afterEnter={() => {
        setModalOpen(true);
      }}
      afterLeave={() => {
        setModalOpen(false);
        setCustomDataFetched(false);
      }}
    >
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => setIsShowing(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black bg-opacity-60"
            aria-hidden="true"
          />
        </Transition.Child>

        <div className="flex h-screen max-h-screen flex-col justify-center overflow-hidden p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto flex h-full w-full max-w-5xl transform flex-col overflow-hidden rounded-2xl bg-white px-5 py-6 text-left align-middle shadow-xl transition-all sm:px-12 sm:py-8">
              <div className="absolute right-3 top-3">
                <button
                  type="button"
                  className="rounded-md bg-white p-1.5 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => setIsShowing(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <Dialog.Title
                as="h1"
                className="mb-3 font-serif text-2xl font-bold leading-6 text-gray-900"
              >
                Example Problems
              </Dialog.Title>
              <Dialog.Description
                id="mathBlockExample"
                as="div"
                className="relative grow overflow-y-auto bg-white px-2 pt-5 leading-8 sm:px-5 lg:px-9"
                aria-live="polite"
              >
                <div
                  ref={loadingContent}
                  className="flex flex-col items-center justify-center"
                >
                  <div
                    style={{ borderTopColor: "transparent" }}
                    className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-blue-400"
                  ></div>
                  <h2 className="mt-2 text-center text-xl font-bold">
                    Loading...
                  </h2>
                </div>
                <div ref={mathBlockContent} className="invisible">
                  <h2
                    id="problemPromptExample"
                    role="group"
                    aria-hidden={customPromptA11y !== undefined}
                    dangerouslySetInnerHTML={{
                      __html: currentExample?.prompt
                        ? currentExample.prompt
                        : null,
                    }}
                  ></h2>
                  {customPromptA11y !== undefined && customPromptA11y}
                  <div
                    key={`examplepage:${currentExample?._id}`}
                    id={pageID}
                    className="display-problem problem-page"
                  >
                    <Problem
                      displayData={processlines(currentExample?.lines)}
                      problemData={currentExample}
                      resizingData={katexResizingData["solution"]}
                      locString="solution"
                    />
                  </div>
                </div>
                {/* Gradient div to imply fading in on scroll */}
                <div className="fadeOver pointer-events-none sticky bottom-0 left-0 z-10 h-16 w-full bg-gradient-to-t from-white from-10% to-white/25"></div>
              </Dialog.Description>
              <div className="mt-0.5 flex justify-between gap-3 border-t pt-6">
                <button
                  type="button"
                  className={clsx(
                    "inline-flex justify-center rounded border border-transparent px-4 py-2 text-sm text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2",
                    currentExampleIndex <= 0 || isFetching
                      ? "pointer-events-none bg-dm-brand-blue-500/50"
                      : "bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600"
                  )}
                  onClick={() => anotherExample(false)}
                  disabled={currentExampleIndex <= 0 || isFetching}
                >
                  <ArrowLeftIcon className="h-5 w-5 pr-1" aria-hidden="true" />{" "}
                  Previous Example
                </button>
                <button
                  type="button"
                  className={clsx(
                    "inline-flex justify-center rounded border border-transparent px-4 py-2 text-sm text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2",
                    currentExampleIndex >= maxExamples - 1 || isFetching
                      ? "pointer-events-none bg-dm-brand-blue-500/50"
                      : "bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600"
                  )}
                  onClick={() => anotherExample(true)}
                  disabled={
                    currentExampleIndex >= maxExamples - 1 || isFetching
                  }
                >
                  Next Example{" "}
                  <ArrowRightIcon className="h-5 w-5 pl-1" aria-hidden="true" />
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
