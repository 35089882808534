import { Learner, LearnerAccountStatus } from "../../types";

export function getLearnerAccountStatus(
  learner: Pick<
    Learner,
    "subscriptionExpiration" | "subscriptionWillCancel" | "inviteCode"
  >
): LearnerAccountStatus {
  if (new Date(learner.subscriptionExpiration).getTime() < Date.now()) {
    return "inactive";
  }
  if (learner.subscriptionWillCancel) {
    return "canceled";
  }
  if (learner.inviteCode) {
    return "pending";
  }
  return "active";
}
