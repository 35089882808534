import React, { useState } from "react";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../../manager/utils";
import axios from "axios";
import Modal from "../../../student/components/generic/Modal";
import { ResponsiveImage } from "../ResponsiveImage";
import { getFilePath } from "../../../utils";
import { useLearnerContext } from "../../contexts/LearnerContext";

type Props = {
  courseId: string;
};

const ArchiveCourse = (props: Props) => {
  const learnerContext = useLearnerContext();
  const [showModal, setShowModal] = useState<boolean>(false);

  const toastContext = useDeltaToastContext();

  const { mutate: archiveCourse } = useMutation(
    (body: { courseId: string }) => {
      return axios.post(
        deltamathAPI() + "/learner/data/archive_course",
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: () => {
        // put this in a timeout so the modal can gracefully fade out
        // we then archive the course which removes it from the sidebar
        setShowModal(false);
        setTimeout(() => {
          learnerContext.archiveCourse(props.courseId);
        }, 350);
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message: "There was an issue archiving your course.",
        });
      },
    }
  );

  const hideCourse = () => {
    const body = { courseId: props.courseId };
    archiveCourse(body);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div
        data-tip={`Hide Course`}
        data-for={"sidebar-tooltip"}
        data-place="top"
      >
        <i
          className="far fa-times z-20 mr-2 text-lg !text-dm-gray-200"
          onClick={() => {
            setShowModal(true);
          }}
        />
      </div>
      <Modal
        visible={showModal}
        onClose={closeModal}
        body={
          <div className="flex flex-col items-center">
            <ResponsiveImage
              className="mb-4 max-w-52 object-contain"
              srcs={[
                getFilePath(
                  "/images/learner/archivecourse/deltie-thinking@2x.png"
                ),
                getFilePath(
                  "/images/learner/archivecourse/deltie-thinking.png"
                ),
              ]}
              alt="Deltie thumb on chin thinking"
            />
            <h3 className="mt-10 w-full text-2xl font-bold">
              Hide Course from Sidebar?
            </h3>
            <p className="mt-4">
              {
                "Your progress is saved. The course will be hidden from the sidebar. You can access it anytime from 'All Courses'. It will reappear here when you resume working on it."
              }
            </p>
          </div>
        }
        onConfirm={hideCourse}
        confirmButtonText="Hide Course"
        secondaryButtonText="Keep Course"
        secondaryOnClick={closeModal}
      />
    </>
  );
};

export default ArchiveCourse;
