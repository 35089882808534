import { useState } from "react";
import Button from "../../../student/components/generic/button";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { REACT_APP_MANAGER_LINK, useDMQuery } from "../../../utils";
import DraggableTable from "../../../shared/DraggableTable/DraggableTable";
import { Column } from "react-table";
import { CourseType, CourseVersionHistory } from "../../types";
import Select, { SingleValue } from "react-select";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import clsx from "clsx";
import { useDocumentTitle } from "usehooks-ts";

const CourseBuilder = () => {
  useDocumentTitle("Course Builder");
  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();
  const navigate = useNavigate();
  const [newCourseName, setNewCourseName] = useState<string>("");
  const [enabled, setEnabled] = useState(false);

  const { data, status } = useDMQuery<CourseType[]>({
    path: "/manager_new/parent-portal/courses",
  });

  const createCourse = useMutation(
    (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/create_course`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries("/manager_new/parent-portal/courses");
        setNewCourseName("");
        toastContext.addToast({
          status: "Success",
          message: "Course Created",
        });
      },
      onError(e) {
        toastContext.addToast({
          status: "Error",
          message: "Problem creating corse",
        });
      },
    }
  );

  const newVersion = useMutation(
    (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/newVersion`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess(data) {
        queryClient.invalidateQueries("/manager_new/parent-portal/courses");
        navigate(
          `${REACT_APP_MANAGER_LINK}/course/${data.data._id}?version=${
            data.data.versionHistory[data.data.versionHistory.length - 1]
              .versionKey
          }`
        );
      },
      onError(e) {
        toastContext.addToast({
          status: "Error",
          message: "Issue creating new version",
        });
      },
    }
  );

  const courseReorder = useMutation(
    (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/order_courses`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries("/manager_new/parent-portal/courses");
        toastContext.addToast({
          status: "Success",
          message: "Courses Reordered",
        });
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message: "Issue re-ordering courses",
        });
      },
    }
  );

  const create = () => {
    if (!newCourseName || newCourseName.trim() === "") {
      toastContext.addToast({
        message: "You cannot create a course without a name",
        status: "Error",
      });
      return;
    }
    const body = { name: newCourseName };
    createCourse.mutate(JSON.stringify(body));
  };

  const updateData = (data: CourseType[]) => {
    const body = {
      courseIds: data.map((c) => c._id),
    };
    courseReorder.mutate(JSON.stringify(body));
  };

  const columns: Column[] = [
    {
      Header: "#",
      Cell: (props: any) => {
        props.row._id;
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (props: any) => {
        return (
          <Button
            href={`${REACT_APP_MANAGER_LINK}/course/${
              props.row.original._id
            }?version=${
              props.row.original.versionHistory[
                props.row.original.versionHistory.length - 1
              ].versionKey
            }`}
            type="link"
          >
            {props.row.original.name}
          </Button>
        );
      },
    },
    {
      Header: "# of Units",
      accessor: "versionHistory",
      Cell: (props: any) => {
        if (
          props.row.original.versionHistory &&
          props.row.original.versionHistory.length > 0
        ) {
          return props.row.original.versionHistory[
            props.row.original.versionHistory.length - 1
          ].unitOrder.length;
        }
        return 0;
      },
    },
    {
      Header: "Version",
      Cell: (props: any) => {
        if (
          props.row.original.versionHistory &&
          props.row.original.versionHistory.length > 0
        ) {
          return (
            <div className="flex justify-center">
              <Select
                placeholder="Select Specific Version"
                name="colorstypeOptions"
                options={props.row.original.versionHistory.map(
                  (v: CourseVersionHistory) => {
                    return {
                      value: v.versionKey,
                      label: `V${v.versionName}`,
                    };
                  }
                )}
                isClearable={true}
                isSearchable={false}
                closeMenuOnSelect={false}
                className="w-56"
                onChange={(
                  newValue: SingleValue<{ label: string; value: string }>
                ) => {
                  if (newValue) {
                    navigate(
                      `${REACT_APP_MANAGER_LINK}/course/${props.row.original._id}?version=${newValue.value}`
                    );
                  }
                }}
              />
            </div>
          );
        }
        return 0;
      },
    },
    {
      Header: "State",
      Cell: (props: any) => {
        if (
          props.row.original.versionHistory &&
          props.row.original.versionHistory.length > 0
        ) {
          const state =
            props.row.original.versionHistory[
              props.row.original.versionHistory.length - 1
            ].state;
          if (state === "draft") {
            return <div className="rounded-full border px-2 py-1">Draft</div>;
          } else if (state === "published") {
            return (
              <div className="rounded-full border bg-dm-success-200 px-2 py-1">
                Published
              </div>
            );
          } else if (state === "deleted") {
            return (
              <div className="rounded-full border bg-dm-error-200 px-2 py-1">
                Deleted
              </div>
            );
          }
          return props.row.original.versionHistory[
            props.row.original.versionHistory.length - 1
          ].state;
        }
      },
    },
    {
      Header: "Last Edited By",
      Cell: (props: any) => {
        if (
          props.row.original.editLog &&
          props.row.original.editLog.length > 0
        ) {
          return props.row.original.editLog[
            props.row.original.editLog.length - 1
          ].user;
        }
        return "No One";
      },
    },
    {
      Header: "Last Edited",
      Cell: (props: any) => {
        if (
          props.row.original.editLog &&
          props.row.original.editLog.length > 0
        ) {
          return new Date(
            props.row.original.editLog[
              props.row.original.editLog.length - 1
            ].time
          ).toLocaleString();
        }
        return "No Date";
      },
    },
  ];

  const filteredData =
    (!enabled && data
      ? data.filter(
          (x) =>
            x.versionHistory[x.versionHistory.length - 1].state !== "deleted"
        )
      : data) || [];

  return (
    <div className="px-6">
      <h2 className="mb-6 pt-10 text-2xl font-bold">Course Builder</h2>
      <h5 className="mb-1 text-lg">New Course Name</h5>
      <form
        className="mb-10 flex gap-4"
        onSubmit={async (e) => {
          e.preventDefault();
        }}
      >
        <input
          type="text"
          name="classcode"
          id="classcode"
          className="block h-12 w-96 rounded-none rounded-l-md border-gray-300 px-2 py-1 text-sm placeholder-gray-300 ring-inset placeholder:text-sm focus:border-dm-brand-blue-500 focus:ring-0 focus:ring-dm-brand-blue-500"
          placeholder="New Course Name"
          value={newCourseName}
          onChange={(e) => setNewCourseName(e.target.value)}
        />
        <Button className="flex items-center gap-2" onClick={create} submit>
          <i className="far fa-plus-circle cursor-pointer text-2xl" />
          Create New Course
        </Button>
      </form>
      {status !== "success" || filteredData.length === 0 ? (
        <div className="my-20 w-full bg-white py-20">
          <div className="text-center">
            <h2 className="my-10 text-xl font-bold">
              There are no courses yet
            </h2>
          </div>
        </div>
      ) : (
        <div>
          {/* <DeltaMathTable columns={columns} data={data} /> */}
          <Switch.Group>
            <div className="mb-4 flex items-center gap-x-2">
              <Switch.Label
                passive
                className={clsx(
                  "min-w-[30px] text-right text-xs text-dm-charcoal-600",
                  !enabled && "font-bold"
                )}
              >
                Hide Deleted
              </Switch.Label>
              <Switch
                checked={enabled}
                onChange={setEnabled}
                className={`${
                  enabled ? "bg-dm-brand-blue-800" : "bg-dm-brand-blue-500"
                } relative inline-flex h-6 w-11 shrink-0 items-center rounded-full`}
              >
                <span className="sr-only">daily/weekly</span>
                <span
                  aria-hidden="true"
                  className={`${
                    enabled ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <Switch.Label
                passive
                className={clsx(
                  "min-w-[44px] text-left text-xs text-dm-charcoal-600",
                  enabled && "font-bold"
                )}
              >
                Show Deleted
              </Switch.Label>
            </div>
          </Switch.Group>
          <DraggableTable
            cols={columns}
            data={filteredData}
            updateData={updateData}
          />
        </div>
      )}
    </div>
  );
};

export default CourseBuilder;
