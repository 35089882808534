import { createContext, useContext, useEffect, useState } from "react";
import { useDMQuery } from "../../utils";
import { Learner, Progress } from "../types";
import { useLearnerContext } from "./LearnerContext";
import { noop } from "lodash";

interface ParentState {
  learners: Learner[];
  currentLearnerId: string | null;
}

interface ParentContextApi {
  currentLearner: Learner | null;
  learners: Learner[];
  fetchChildLearners: () => void;
}

const ParentContext = createContext<{
  state: ParentState;
  setState: React.Dispatch<React.SetStateAction<ParentState>>;
  fetchChildLearners: () => void;
}>({
  state: {
    learners: [],
    currentLearnerId: null,
  },
  setState: noop,
  fetchChildLearners: noop,
});

/** Returns the parent context API for interacting with parent/child data */
export function useParentContext(): ParentContextApi {
  const { state, fetchChildLearners } = useContext(ParentContext);

  return {
    currentLearner:
      state.learners.find((l) => l._id === state.currentLearnerId) || null,
    learners: state.learners,
    fetchChildLearners,
  };
}

/** Context provider for parent users */
export const ParentContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<ParentState>({
    learners: [],
    currentLearnerId: null,
  });

  const learnerContext = useLearnerContext();

  useEffect(() => {
    learnerContext.setParentCurrentLearner(state.currentLearnerId || undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentLearnerId]);

  const { refetch: fetchChildLearners } = useDMQuery({
    path: "/learner/parent/my-learners",
    method: "get",
    queryOptions: {
      enabled: false,
      onSuccess(response: { learners: Learner[] }) {
        setState({
          learners: response.learners,
          currentLearnerId: state.currentLearnerId
            ? state.currentLearnerId
            : response.learners.length > 0
            ? response.learners[0]._id
            : null,
        });
      },
    },
  });

  const { refetch: progressForParentRefetch } = useDMQuery<Progress[]>({
    path: `/learner/parent/learner-progress/${state.currentLearnerId}`,
    queryOptions: {
      enabled: false,
      onSuccess: (data: Progress[]) => {
        learnerContext.setProgress(data);
      },
    },
  });

  useEffect(() => {
    if (state.currentLearnerId) {
      progressForParentRefetch();
    }
  }, [progressForParentRefetch, state.currentLearnerId]);

  useEffect(() => {
    fetchChildLearners();
  }, [fetchChildLearners]);

  return (
    <ParentContext.Provider value={{ state, setState, fetchChildLearners }}>
      {children}
    </ParentContext.Provider>
  );
};
