import { noop } from "lodash";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { CancelStep, CancelFeedbackFormData } from "./types";
import { unreachableCase, useDMQuery } from "../../../../utils";
import { useParentContext } from "../../../contexts/ParentContext";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../../manager/utils";
import { withJsonHeader } from "../../../../shared/axiosUtils";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";

interface Discount {
  percentOff: number;
  numMonths: number; // number of months
}

type RadioState = "all" | "some" | null;

const CancellationContext = createContext<{
  refetchSubscriptionData: () => void;
  showCancelModal: boolean;
  setShowCancelModal: (show: boolean) => void;
  onClose: () => void;
  step: CancelStep;
  setStep: (step: CancelStep) => void;
  confirmDisabled: boolean;
  setConfirmDisabled: (disabled: boolean) => void;
  secondaryDisabled: boolean;
  setSecondaryDisabled: (disabled: boolean) => void;
  reasonFormData: CancelFeedbackFormData;
  setReasonFormData: (data: CancelFeedbackFormData) => void;
  discount: Discount | null;
  setDiscount: (discount: Discount | null) => void;
  showDiscountToast: boolean;
  setShowDiscountToast: (show: boolean) => void;
  selectedLearners: string[];
  setSelectedLearners: (learners: string[]) => void;
  learnerSelectRadioState: RadioState;
  setLearnerSelectRadioState: (state: RadioState) => void;
}>({
  refetchSubscriptionData: noop,
  showCancelModal: false,
  setShowCancelModal: noop,
  onClose: noop,
  step: "listFeatures",
  setStep: noop,
  confirmDisabled: false,
  setConfirmDisabled: noop,
  secondaryDisabled: false,
  setSecondaryDisabled: noop,
  reasonFormData: {} as CancelFeedbackFormData,
  setReasonFormData: noop,
  discount: null,
  setDiscount: noop,
  selectedLearners: [],
  setSelectedLearners: noop,
  showDiscountToast: false,
  setShowDiscountToast: noop,
  learnerSelectRadioState: null,
  setLearnerSelectRadioState: noop,
});

export function useCancellationContext() {
  const toastContext = useDeltaToastContext();
  const learnerContext = useLearnerContext();
  const { learners } = useParentContext();
  const { fetchChildLearners } = useParentContext();
  const {
    refetchSubscriptionData,
    showCancelModal,
    setShowCancelModal,
    onClose,
    step,
    setStep,
    confirmDisabled,
    setConfirmDisabled,
    secondaryDisabled,
    setSecondaryDisabled,
    reasonFormData,
    setReasonFormData,
    discount,
    setDiscount,
    showDiscountToast,
    setShowDiscountToast,
    selectedLearners,
    setSelectedLearners,
    learnerSelectRadioState,
    setLearnerSelectRadioState,
  } = useContext(CancellationContext);

  const isIndependentLearner =
    selectedLearners.length === 1 &&
    selectedLearners[0] === learnerContext.learner?._id;

  const { mutateAsync: cancelFeedbackMutation } = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/cancellation-feedback`,
      JSON.stringify(reasonFormData),
      withJsonHeader()
    );
  });

  const submitFeedback = async () => {
    try {
      await cancelFeedbackMutation();
    } catch (e) {
      // Ignore
    }
  };

  const { refetch: discountQuery } = useDMQuery<{
    discount: { percentOff: number; numMonths: number };
  }>({
    path: "/payments/subscriptions/discount/get",
    queryOptions: { enabled: false },
  });

  const { mutateAsync: applyDiscountMutation } = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/discount/apply`,
      "",
      withJsonHeader()
    );
  });

  const fetchDiscount = async () => {
    try {
      const { data } = await discountQuery();
      if (data?.discount) {
        setDiscount(data.discount);
        return true;
      }
    } catch (e) {
      // Ignore
    }
    return false;
  };

  const { mutateAsync: cancelSubscriptionMutation } = useMutation(() => {
    // Cancel for independent learner
    if (isIndependentLearner) {
      return axios.post(
        `${deltamathAPI()}/payments/subscriptions/cancel/self`,
        "",
        withJsonHeader()
      );
    }

    // Cancel for children
    else {
      return axios.post(
        `${deltamathAPI()}/payments/subscriptions/cancel/child`,
        JSON.stringify({ learnerIds: selectedLearners }),
        withJsonHeader()
      );
    }
  });

  const cancelSubscription = async () => {
    try {
      await cancelSubscriptionMutation();
      setStep("confirmation");
    } catch (e: any) {
      toastContext.addToast({
        message: e.response.data.message || "Failed to cancel subscription",
        status: "Error",
      });
    }
  };

  const onCancel = async () => {
    switch (step) {
      case "multipleLearners":
        onClose();
        break;
      case "listFeatures":
        onClose();
        break;
      case "chooseReason":
        setConfirmDisabled(false);
        setStep("listFeatures");
        break;
      case "discount":
        setSecondaryDisabled(true);
        await cancelSubscription();
        setSecondaryDisabled(false);
        break;
      case "confirmation":
        // Should be impossible
        throw new Error("Cannot cancel from confirmation step");
      default:
        unreachableCase(step);
    }
  };

  const onConfirm = async () => {
    switch (step) {
      case "multipleLearners":
        if (learnerSelectRadioState === "all") {
          setSelectedLearners(learners.map((learner) => learner._id));
          setConfirmDisabled(false);
          setStep("listFeatures");
        } else {
          setStep("listFeatures");
        }
        break;
      case "listFeatures":
        setStep("chooseReason");
        break;
      case "chooseReason":
        setConfirmDisabled(true);
        await submitFeedback();
        if (
          (isIndependentLearner ||
            selectedLearners.length === learners.length) &&
          (await fetchDiscount())
        ) {
          setStep("discount");
        } else {
          await cancelSubscription();
        }
        setConfirmDisabled(false);
        break;
      case "discount":
        setConfirmDisabled(true);
        await applyDiscountMutation();
        await refetchSubscriptionData();
        setConfirmDisabled(false);
        onClose();
        setTimeout(() => setShowDiscountToast(true), 500);
        setTimeout(() => setShowDiscountToast(false), 5500);
        break;
      case "confirmation":
        setConfirmDisabled(true);
        await Promise.all([
          refetchSubscriptionData(),
          fetchChildLearners(),
          learnerContext.refetchLearner(),
        ]);
        setConfirmDisabled(false);
        onClose();
        break;
      default:
        unreachableCase(step);
    }
  };

  return {
    refetchSubscriptionData,
    showCancelModal,
    setShowCancelModal,
    step,
    confirmDisabled,
    setConfirmDisabled,
    secondaryDisabled,
    reasonFormData,
    setReasonFormData,
    onCancel,
    onConfirm,
    onClose,
    discount,
    showDiscountToast,
    selectedLearners,
    setSelectedLearners,
    learnerSelectRadioState,
    setLearnerSelectRadioState,
  };
}

export const CancellationContextProvider: React.FC<
  PropsWithChildren<{ refetchSubscriptionData: () => void }>
> = ({ refetchSubscriptionData, children }) => {
  const { learners } = useParentContext();
  const [step, setStep] = useState<CancelStep>("listFeatures");
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [secondaryDisabled, setSecondaryDisabled] = useState(false);
  const [reasonFormData, setReasonFormData] = useState<CancelFeedbackFormData>({
    reasons: [],
    missingCourseExplanation: undefined,
    differentServiceExplanation: undefined,
    otherExplanation: undefined,
  });
  const [discount, setDiscount] = useState<Discount | null>(null);
  const [showDiscountToast, setShowDiscountToast] = useState(false);
  const [selectedLearners, setSelectedLearners] = useState<string[]>([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [learnerSelectRadioState, setLearnerSelectRadioState] =
    useState<RadioState>(null);

  const resetState = () => {
    setSelectedLearners([]);
    setConfirmDisabled(false);
    setSecondaryDisabled(false);
    setLearnerSelectRadioState(null);
    setReasonFormData({
      reasons: [],
      missingCourseExplanation: undefined,
      differentServiceExplanation: undefined,
      otherExplanation: undefined,
    });
  };

  useEffect(() => {
    if (showCancelModal) {
      setStep(
        learners.length === 1 || selectedLearners.length === 1
          ? "listFeatures"
          : "multipleLearners"
      );
    }
    // Only run when visibility changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCancelModal]);

  return (
    <CancellationContext.Provider
      value={{
        refetchSubscriptionData,
        showCancelModal,
        setShowCancelModal,
        onClose: () => {
          setTimeout(resetState, 1000);
          setShowCancelModal(false);
        },
        step,
        setStep,
        confirmDisabled,
        setConfirmDisabled,
        secondaryDisabled,
        setSecondaryDisabled,
        reasonFormData,
        setReasonFormData,
        discount,
        setDiscount,
        showDiscountToast,
        setShowDiscountToast,
        selectedLearners,
        setSelectedLearners,
        learnerSelectRadioState,
        setLearnerSelectRadioState,
      }}
    >
      {children}
    </CancellationContext.Provider>
  );
};
