import { useMemo } from "react";
import Modal from "../../../../student/components/generic/Modal";
import { unreachableCase } from "../../../../utils";
import { CancelFeatures } from "./CancelFeatures";
import { CancelFeedback } from "./CancelFeedback";
import { useCancellationContext } from "./CancellationContext";
import { DiscountOffer } from "./DiscountOffer";
import { CancelConfirmation } from "./CancelConfirmation";
import { CancelMultipleLearners } from "./CancelMultipleLearners";
import { Transition } from "@headlessui/react";
import { useParentContext } from "../../../contexts/ParentContext";
import { useLearnerContext } from "../../../contexts/LearnerContext";

interface StepConfig {
  Component: React.ComponentType;
  cancelText: string | null;
  confirmText: string;
}

export const CancelSubscriptionModal: React.FC = () => {
  const { learner: accountOwner } = useLearnerContext();
  const { learners } = useParentContext();
  const {
    showCancelModal,
    step,
    onClose,
    onCancel,
    onConfirm,
    confirmDisabled,
    secondaryDisabled,
    discount,
    showDiscountToast,
    selectedLearners,
  } = useCancellationContext();

  const isCancelingOneLearner = selectedLearners.length === 1;
  const isPartialCancel = selectedLearners.length < learners.length;
  const isIndependentLearner =
    isCancelingOneLearner && selectedLearners[0] === accountOwner?._id;

  const currentStep = useMemo<StepConfig>(() => {
    switch (step) {
      case "multipleLearners":
        return {
          Component: CancelMultipleLearners,
          cancelText: "Back",
          confirmText: "Continue to Cancel",
        };
      case "listFeatures":
        return {
          Component: CancelFeatures,
          cancelText: "Keep Subscription",
          confirmText: isIndependentLearner
            ? "Yes, I'm Sure. Cancel."
            : "Continue to Cancel",
        };
      case "chooseReason":
        return {
          Component: CancelFeedback,
          cancelText: "Go Back",
          confirmText:
            isCancelingOneLearner && isPartialCancel
              ? "Cancel Subscription for This Learner"
              : !isCancelingOneLearner && isPartialCancel
              ? "Cancel Subscription for These Learners"
              : "Continue to Cancel",
        };
      case "discount":
        return {
          Component: DiscountOffer,
          cancelText:
            !isCancelingOneLearner && !isPartialCancel
              ? "Cancel All Learner Subscriptions"
              : "Cancel Subscription",
          confirmText: `Get ${discount?.percentOff}% off`,
        };
      case "confirmation":
        return {
          Component: CancelConfirmation,
          cancelText: null,
          confirmText: "Got It",
        };
      default:
        return unreachableCase(step);
    }
  }, [
    discount?.percentOff,
    isCancelingOneLearner,
    isIndependentLearner,
    isPartialCancel,
    step,
  ]);

  return (
    <>
      <Modal
        visible={showCancelModal}
        onClose={onClose}
        closeX
        bodyClassName="!px-4 pb-6 !pt-12 sm:!px-10 sm:pb-8"
        body={<currentStep.Component />}
        secondaryButtonText={currentStep.cancelText ?? undefined}
        secondaryOnClick={onCancel}
        secondaryDisabled={secondaryDisabled}
        confirmButtonText={currentStep.confirmText}
        confirmDisabled={confirmDisabled}
        onConfirm={onConfirm}
      />

      <div className="fixed bottom-4 right-4">
        <Transition
          show={showDiscountToast}
          enter="transition-all duration-300 ease-out"
          enterFrom="opacity-0 translate-y-10"
          enterTo="transform opacity-100"
          leave="transition-all duration-300 ease-in"
          leaveFrom="transform opacity-100"
          leaveTo="opacity-0 translate-y-10"
        >
          <div className="flex w-72 gap-2 rounded bg-dm-charcoal-800 px-3 py-2 text-white shadow-lg">
            <i className="far fa-heart mt-1" aria-hidden />
            <span aria-live="assertive">
              {discount?.numMonths === 1
                ? `You have successfully updated your subscription to the limited offer of ${discount?.percentOff}% off for the next ${discount?.numMonths} month!`
                : `You have successfully updated your subscription to the limited offer of ${discount?.percentOff}% off for the next ${discount?.numMonths} months!`}
            </span>
          </div>
        </Transition>
      </div>
    </>
  );
};
