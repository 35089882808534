import {
  FormEventHandler,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParentContext } from "../../../contexts/ParentContext";
import { MultiLearnerHeader } from "./MultiLearnerHeader";
import { SignupFormField } from "../../Home/Signup/SignupFormField";
import {
  ConditionalPasswordErrorMessages,
  evaluatePassword,
  useDMQuery,
} from "../../../../utils";
import Button from "../../../../student/components/generic/button";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../../manager/utils";
import md5 from "md5";
import { withJsonHeader } from "../../../../shared/axiosUtils";
import clsx from "clsx";

export interface ChildSignupPayload {
  first: string;
  last: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const BLANK_DATA: ChildSignupPayload = {
  first: "",
  last: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const AddLearnersForm: React.FC<{
  numLearners: number;
  next: () => void;
}> = ({ numLearners, next }) => {
  const { fetchChildLearners, learners } = useParentContext();
  const [data, setData] = useState<ChildSignupPayload>(BLANK_DATA);
  const [formErrors, setFormErrors] = useState<{
    [K in keyof ChildSignupPayload]?: string | ReactElement;
  }>({});
  const [nonFieldError, setNonFieldError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasEmail, setHasEmail] = useState(true);
  const firstNameFieldRef = useRef<HTMLInputElement>(null);

  const { refetch: fetchEmailAvailability } = useDMQuery<{
    available: boolean;
  }>({
    path: `/learner/beta/username_check/${data.email}`,
    queryOptions: {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  });

  const isFormDisabled =
    Object.values(formErrors).some((e) => e !== undefined) ||
    !data.first.trim().length ||
    !data.last.trim().length ||
    !data.email.trim().length ||
    (!hasEmail &&
      !data.password.trim().length &&
      !data.confirmPassword.trim.length &&
      !evaluatePassword(data.password.trim()) &&
      data.password.trim() !== data.confirmPassword.trim());

  const { mutateAsync: createChildAccountMutation } = useMutation(
    ({ payload }: { payload: ChildSignupPayload }) => {
      return axios.post<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/parent/create-child-account`,
        JSON.stringify({
          ...payload,
          password:
            payload.password.trim().length > 0
              ? md5(`deltamath${payload.password.trim()}`)
              : undefined,
        }),
        withJsonHeader()
      );
    }
  );

  useEffect(() => {
    fetchChildLearners();
    firstNameFieldRef.current?.focus();
  }, [fetchChildLearners]);

  useEffect(() => {
    const t = setTimeout(() => {
      if (
        data.password.trim().length &&
        data.confirmPassword.trim().length &&
        data.password.trim() !== data.confirmPassword.trim()
      ) {
        setFormErrors({
          ...formErrors,
          confirmPassword: "Passwords do not match",
        });
      } else {
        setFormErrors({ ...formErrors, confirmPassword: undefined });
      }
    }, 500);
    return () => clearTimeout(t);
    // Include formErrors causes a render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.password, data.confirmPassword]);

  const handleCheckEmail = async (errorMessage: string) => {
    // Ignore invalid emails
    if (data.email.trim().length === 0 || !data.email.includes("@")) {
      return;
    }
    const response = await fetchEmailAvailability();
    if (!response?.data) {
      return;
    }
    const { available } = response.data;
    if (!available) {
      setFormErrors({
        ...formErrors,
        email: errorMessage,
      });
    } else {
      setFormErrors({ ...formErrors, email: undefined });
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setNonFieldError(undefined);
    setIsLoading(true);
    try {
      await createChildAccountMutation({ payload: data });
      await fetchChildLearners();
      if (learners.length + 1 === numLearners) {
        next();
      } else {
        setData(BLANK_DATA);
        setIsLoading(false);
        setHasEmail(true);
        firstNameFieldRef.current?.focus();
      }
    } catch (e: any) {
      setNonFieldError(
        e.response?.data?.message ||
          "There was an error while creating your account"
      );
      setIsLoading(false);
    }
  };

  return (
    <div className="flex w-full flex-col items-center sm:px-4 md:px-14">
      {numLearners > 1 && (
        <MultiLearnerHeader numLearners={numLearners} learners={learners} />
      )}

      <form
        className="flex w-full flex-col gap-8 px-4 sm:w-[300px] sm:px-0"
        onSubmit={handleSubmit}
      >
        <h1
          className={clsx(
            "mb-4 text-center font-serif text-lg font-bold text-dm-brand-blue-600",
            numLearners === 1 ? "sm:text-2xl" : "md:hidden"
          )}
        >
          {numLearners === 1 ? "Add Learner" : "Add Learners"}
        </h1>
        <div className="flex flex-col gap-4">
          <SignupFormField
            ref={firstNameFieldRef}
            label="Learner's First Name"
            value={data.first}
            error={formErrors.first}
            onChange={(e) => setData({ ...data, first: e.currentTarget.value })}
          />
          <SignupFormField
            label="Learner's Last Name"
            value={data.last}
            error={formErrors.last}
            onChange={(e) => setData({ ...data, last: e.currentTarget.value })}
          />
          {hasEmail && (
            <SignupFormField
              label="Learner's Email"
              value={data.email}
              error={formErrors.email}
              onChange={(e) =>
                setData({ ...data, email: e.currentTarget.value })
              }
              onBlur={() =>
                handleCheckEmail(
                  "There is already an account with this email address"
                )
              }
            />
          )}
          {!hasEmail && (
            <>
              <SignupFormField
                label="Learner's Username"
                value={data.email}
                error={formErrors.email}
                onChange={(e) =>
                  setData({ ...data, email: e.currentTarget.value })
                }
                onBlur={() =>
                  handleCheckEmail(
                    "There is already an account with this username"
                  )
                }
              />
              <SignupFormField
                label="Create Learner Password"
                type="password"
                value={data.password}
                error={
                  <ConditionalPasswordErrorMessages password={data.password} />
                }
                onChange={(e) =>
                  setData({ ...data, password: e.currentTarget.value })
                }
              />
              <SignupFormField
                label="Confirm Learner Password"
                type="password"
                value={data.confirmPassword}
                error={formErrors.confirmPassword}
                onChange={(e) => {
                  setData({ ...data, confirmPassword: e.currentTarget.value });
                }}
              />
            </>
          )}
          {nonFieldError && (
            <div className="rounded bg-dm-error-100 p-4 text-center text-sm text-dm-error-500">
              {nonFieldError}
            </div>
          )}
        </div>
        <Button disabled={isFormDisabled} isLoading={isLoading}>
          {hasEmail ? "Email Invite Link" : "Create Learner Account"}
        </Button>
        <button
          className="text-dm-brand-blue-500 underline-offset-2 hover:underline"
          type="button"
          onClick={() => {
            setHasEmail(!hasEmail);
            setFormErrors({ ...formErrors, email: undefined });
          }}
        >
          {hasEmail
            ? "My child does not have an email"
            : "Never mind, I want to use email"}
        </button>
      </form>
    </div>
  );
};
