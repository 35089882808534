import { useState } from "react";
import { REACT_APP_LEARNER_LINK, useDMQuery } from "../../utils";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { format } from "date-fns";
import { Infotip } from "../../shared/Infotip";
import { round } from "lodash";
import { DMToggleSingleLabel } from "../utils/functions";
import { deltamathAPI, filterEmployeeData } from "../utils";
import Button from "../../student/components/generic/button";
import { useMutation } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useDocumentTitle } from "usehooks-ts";

const BetaUsersList = () => {
  useDocumentTitle("Beta Users");
  const [includeEmployees, setIncludeEmployees] = useState<boolean>(false);
  const navigate = useNavigate();
  const toastContext = useDeltaToastContext();

  const { data } = useDMQuery({
    path: "/manager_new/parent-portal/beta_testers",
    queryOptions: {
      refetchOnWindowFocus: false,
    },
  });

  const { mutateAsync: impersonateMutate } = useMutation(
    (learnerId: string) => {
      return axios.post(
        `${deltamathAPI()}/impersonate/staff/learner/${learnerId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
  );

  const impersonate = async (learnerId: string) => {
    try {
      const response = await impersonateMutate(learnerId);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      navigate(`${REACT_APP_LEARNER_LINK}/dashboard`);
    } catch (error: any) {
      const message =
        error?.response?.data?.message ||
        "There was an error impersonating the learner";

      toastContext.addToast({
        title: "Error",
        message,
        status: "Error",
      });
    }
  };

  const tableColumns = [
    {
      Header: "Name",
      accessor: "name",
      align: "left",
    },
    {
      Header: "UserName",
      accessor: "email",
      align: "left",
    },
    {
      Header: (
        <>
          Last Action
          <Infotip
            message="Last Points Earned"
            options={{ tooltipRight: true }}
          >
            <i className="far fa-question-circle ml-2" />
          </Infotip>
        </>
      ),
      accessor: "last_action",
      align: "left",
      Cell: (props: any) => {
        const date = props.cell.row.original.last_action
          ? format(
              new Date(props.cell.row.original.last_action),
              `MMM do, h:mmaaa`
            )
          : undefined;
        return <div>{date || "No Action"}</div>;
      },
    },
    {
      Header: "Course Name",
      align: "left",
      accessor: "course_name",
    },
    {
      Header: "Total Time Spent",
      align: "left",
      accessor: "total_app_time",
      Cell: (props: any) => {
        const hoursSpent = round(
          props.cell.row.original.total_app_time / 3600,
          2
        );
        const minutesSpent =
          Math.floor(props.cell.row.original.total_app_time / 60) % 60;

        return (
          <div>
            {hoursSpent >= 1
              ? `${hoursSpent} hours`
              : `${minutesSpent} minutes`}
          </div>
        );
      },
    },
    {
      Header: "Points Earned",
      align: "left",
      accessor: "points_earned",
    },
    {
      Header: "Points Used",
      align: "left",
      accessor: "points_used",
    },
    {
      Header: "Course % complete",
      accessor: "course_progress",
      Cell: (props: any) => {
        return <div>{props.cell.row.original.course_progress * 100}%</div>;
      },
    },
    {
      Header: "Units Complete",
      align: "left",
      accessor: "units_complete",
    },
    {
      Header: "Sections Complete",
      align: "left",
      accessor: "subunits_complete",
    },
    {
      Header: "Parent Email",
      align: "left",
      accessor: "parent_email",
    },
    {
      Header: "Parent Last Login",
      align: "left",
      accessor: "last_parent_login",
      Cell: (props: any) => {
        const date = format(
          props.cell.row.original.last_parent_login
            ? new Date(props.cell.row.original.last_parent_login)
            : new Date(),
          `MMM do, h:mmaaa`
        );
        return <div>{date}</div>;
      },
    },
    {
      Header: "Impersonate learner",
      accessor: "id",
      align: "left",
      disableSortBy: true,
      Cell: (props: any) => {
        return (
          <Button size="small" onClick={() => impersonate(props.cell.value)}>
            Impersonate learner
          </Button>
        );
      },
    },
    {
      Header: "Impersonate parent",
      accessor: "parent_id",
      align: "left",
      disableSortBy: true,
      Cell: (props: any) => {
        return (
          <Button size="small" onClick={() => impersonate(props.cell.value)}>
            Impersonate parent
          </Button>
        );
      },
    },
  ];

  if (!data) {
    return <>Loading...</>;
  }

  return (
    <div className="flex flex-col gap-4 px-4 py-10">
      <DMToggleSingleLabel
        checked={includeEmployees}
        onChange={(checked: boolean) => setIncludeEmployees(checked)}
        label={"Include Employee Created Users"}
      />
      <DeltaMathTable
        columns={tableColumns}
        data={filterEmployeeData(includeEmployees, data)}
      />
    </div>
  );
};

export default BetaUsersList;
