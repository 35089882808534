import md5 from "md5";
import { deltamathAPI } from "../../manager/utils";
import { PreLoginResponse } from "./types";
import { useUserContext } from "../../shared/contexts/UserContext";
import { processLoginResponse } from "./processLoginResponse";

function processPreloginFactor(num: number): number {
  let factor = 0;
  for (let i = 2; i < num; i++) {
    if (num % i === 0) {
      factor = i;
      break;
    }
  }
  return factor;
}

async function loginRequest(
  email: string,
  password: string,
  accountType: "teacher" | "student" | "admin" | "learner",
  preLoginData: PreLoginResponse
): Promise<unknown> {
  const passwordHash = md5(`deltamath${password}`);
  const factor = processPreloginFactor(preLoginData.number);
  try {
    const loginRequest = await fetch(`${deltamathAPI()}/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password: passwordHash,
        prelogin_factor: factor,
        prelogin_token: preLoginData.token,
        account_type: accountType,
      }),
    });
    return await loginRequest.json();
  } catch (e) {
    if (String(e).toLowerCase().includes("unauthorized")) {
      throw new Error(
        "Unable to login with that username and password. Please try again."
      );
    }
  }
}

async function preloginRequest(email: string): Promise<PreLoginResponse> {
  const preLoginRequest = await fetch(`${deltamathAPI()}/prelogin`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });

  return await preLoginRequest.json();
}

/** Hook to manage logging a user in */
export function useLogin(): (
  email: string,
  password: string,
  accountType: "teacher" | "student" | "admin" | "learner"
) => Promise<{ success: boolean }> {
  const userContext = useUserContext();
  return async (
    email: string,
    password: string,
    accountType: "teacher" | "student" | "admin" | "learner"
  ) => {
    const preloginData = await preloginRequest(email);
    const loginResult = await loginRequest(
      email,
      password,
      accountType,
      preloginData
    );
    return await processLoginResponse(loginResult, userContext);
  };
}
