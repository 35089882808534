import { format } from "date-fns";
import Modal from "../../../student/components/generic/Modal";
import Button from "../../../student/components/generic/button";
import { Learner } from "../../types";
import DebounceTextField from "../BetaSignUp/DebounceTextField";
import { ChangePassword } from "../Profile/ChangePassword";
import { ProfileField } from "../Profile/ProfileField";
import { TextField } from "../Splash/TextField";
import { useState, useEffect } from "react";
import { useDMQuery } from "../../../utils";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { merge } from "lodash";
import { SubscriptionStatusPill } from "../Profile/SubscriptionStatusPill";
import { getLearnerAccountStatus } from "../Profile/getLearnerAccountStatus";
import { useCancellationContext } from "../Profile/Cancellation/CancellationContext";

type FormData = {
  email: string;
  first: string;
  last: string;
};

interface EditAccountResponse {
  data:
    | {
        success: true;
      }
    | {
        success: false;
        errors: {
          [K in keyof FormData]?: string;
        };
      };
}

export const EditAccountInformationModal: React.FC<{
  user: Learner;
  visible: boolean;
  onClose: () => void;
}> = ({ user, visible, onClose }) => {
  const { setShowCancelModal, setSelectedLearners } = useCancellationContext();
  const toastContext = useDeltaToastContext();
  const learnerContext = useLearnerContext();
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    email: "",
    first: "",
    last: "",
  });
  const [formErrors, setFormErrors] = useState<{
    [K in keyof FormData]?: string;
  }>({});

  const { mutate: updateAccount, isLoading: isUpdateAccountLoading } =
    useMutation<EditAccountResponse, unknown, FormData>(
      (body) => {
        return axios.put(
          `${deltamathAPI()}/learner/shared/update_account`,
          JSON.stringify(body),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      },
      {
        onSuccess: (data) => {
          const response = data.data;
          if (response.success) {
            learnerContext.updateLearner({
              ...formData,
            });
            const user: Learner | null = JSON.parse(
              localStorage.getItem("user") || "null"
            );
            if (user) {
              merge(user, formData);
              localStorage.setItem("user", JSON.stringify(user));
            }
            closeModal();
          } else {
            setFormErrors(response.errors);
          }
        },
        onError: () => {
          closeModal();
          toastContext.addToast({
            message: "There was an error updating your account.",
            status: "Error",
          });
        },
      }
    );

  const {
    refetch: fetchEmailAvailability,
    isLoading: isEmailAvailabilityLoading,
  } = useDMQuery({
    path: `/learner/beta/username_check/${formData.email.trim()}`,
    queryOptions: {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data: { available: boolean }) => {
        if (!data.available) {
          formErrors["email"] = "This username / email is already taken.";
        } else if (
          data.available &&
          formErrors["email"] === "This username / email is already taken."
        ) {
          delete formErrors["email"];
        }
      },
    },
  });

  useEffect(() => {
    if (visible) {
      setFormData({
        email: user.email,
        first: user.first,
        last: user.last,
      });
      setFormErrors({});
      setShowPasswordForm(false);
    }
    // Only run this when visibility changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (formData.email && formData.email.trim() !== user.email) {
      fetchEmailAvailability();
    }
  }, [formData.email, user.email, fetchEmailAvailability]);

  const closeModal = () => {
    onClose();
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldType: keyof FormData
  ) => {
    if (isUpdateAccountLoading) return;
    if (!e.target.value.trim()) {
      formErrors[fieldType] = "This is a required field.";
    } else {
      delete formErrors[fieldType];
    }
    setFormData({
      ...formData,
      [fieldType]: e.target.value,
    });
  };

  const confirmEnabled =
    formData.email.trim() &&
    formData.first.trim() &&
    formData.last.trim() &&
    !isEmailAvailabilityLoading &&
    !isUpdateAccountLoading &&
    !Object.keys(formErrors).length;

  const accountStatus = getLearnerAccountStatus(user);

  const cancelLearner = () => {
    onClose();
    setSelectedLearners([user._id]);
    setShowCancelModal(true);
  };

  return (
    <Modal
      visible={visible}
      onClose={closeModal}
      body={
        <div>
          <h2 className="mb-6 text-center font-serif text-2xl font-bold text-dm-brand-blue-600">
            Edit Account Information
          </h2>
          <div className="m-1 flex flex-col gap-6">
            <div className="w-full sm:w-80">
              <TextField
                label="First Name"
                value={formData["first"]}
                error={formErrors["first"]}
                onChange={(e) => handleChange(e, "first")}
              />
            </div>
            <div className="w-full sm:w-80">
              <TextField
                label="Last Name"
                value={formData["last"]}
                error={formErrors["last"]}
                onChange={(e) => handleChange(e, "last")}
              />
            </div>
            <ProfileField label="Account Type">
              {user.entitlements.includes("parent") ? "Parent" : "Learner"}
            </ProfileField>
            <div className="w-full sm:w-80">
              <DebounceTextField
                label="User Name/Email"
                value={formData["email"]}
                error={formErrors["email"]}
                onChange={(e) => handleChange(e, "email")}
              />
            </div>
            {showPasswordForm ? (
              <ProfileField label="Change Password">
                <ChangePassword
                  hideForm={() => {
                    setShowPasswordForm(false);
                  }}
                />
              </ProfileField>
            ) : (
              <ProfileField label="Password">
                <div>{"*".repeat(16)}</div>
                <Button
                  type="link"
                  onClick={() => setShowPasswordForm(true)}
                  className="!py-0 text-left"
                >
                  Change Password
                </Button>
              </ProfileField>
            )}
            <ProfileField label="Status">
              <SubscriptionStatusPill status={accountStatus} />
              {user.entitlements.includes("learner") &&
                !user.subscriptionWillCancel && (
                  <Button type="link" onClick={cancelLearner}>
                    Cancel Subscription
                  </Button>
                )}
            </ProfileField>
            <ProfileField label="Start Date">
              {format(new Date(user.createdAt), "MM'/'dd'/'yy")}
            </ProfileField>
          </div>
        </div>
      }
      onConfirm={() => {
        updateAccount(formData);
      }}
      confirmButtonText="Save Changes"
      confirmDisabled={!confirmEnabled}
      secondaryOnClick={closeModal}
      secondaryButtonText="Cancel"
    />
  );
};
