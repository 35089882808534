import { useState } from "react";
import Button from "../../../../student/components/generic/button";
import { format } from "date-fns";
import { ChangePassword } from "../ChangePassword";
import { Profile } from "./Profile";
import { useParentContext } from "../../../contexts/ParentContext";
import { LearnerPage } from "../../Layouts/LearnerPage";
import { ParentNav } from "../../Parent/ParentNav";
import { ProfileSection } from "./ProfileSection";

export const ParentProfileBeta: React.FC = () => {
  const { currentLearner } = useParentContext();
  const [showForm, setShowForm] = useState<boolean>(false);
  const jsonUser = localStorage.getItem("user");

  // TODO: error handling for when/if user is null / no access to local storage?
  if (!jsonUser) return null;

  const user = JSON.parse(jsonUser);

  return (
    <div className="flex w-full flex-col">
      <ParentNav />
      <LearnerPage title="Profile" sidebar={<></>} dontTrackTime>
        <Profile>
          <ProfileSection
            header="Full Name"
            text={`${user.first} ${user.last}`}
          />
          <ProfileSection header="Email" text={user.email} />
          <ProfileSection
            header={showForm ? "Change Password" : "Password"}
            text={"*".repeat(16)}
            showText={!showForm}
          >
            {!showForm ? (
              <Button
                type="link"
                onClick={() => setShowForm(true)}
                className="!py-0 text-left"
              >
                Change Password
              </Button>
            ) : (
              <ChangePassword hideForm={() => setShowForm(false)} />
            )}
          </ProfileSection>
          {currentLearner && (
            <>
              <hr className="my-1 text-dm-charcoal-100 md:w-[600px]" />
              <ProfileSection
                header="Learner User Name"
                text={`${currentLearner.email}`}
              />
              <ProfileSection
                header="Start Date"
                text={format(
                  new Date(currentLearner.createdAt),
                  "MMMM' 'd', 'yyyy"
                )}
              />
            </>
          )}
        </Profile>
      </LearnerPage>
    </div>
  );
};
