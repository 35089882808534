import { DmLoadingSpinner } from "../../../../manager/utils/functions";
import { useDMQuery } from "../../../../utils";
import { LearnerSubscriptionDetails } from "../../../types";
import { useState } from "react";
import { AddLearnersHeader } from "./AddLearnersHeader";
import { AddLearnersIntro } from "./AddLearnersIntro";
import { AddLearnersForm } from "./AddLearnersForm";
import { useParentContext } from "../../../contexts/ParentContext";
import { AddLearnersOutro } from "./AddLearnersOutro";
import { useLearnerContext } from "../../../contexts/LearnerContext";

export const AddLearnersPage: React.FC = () => {
  const { learner: parent } = useLearnerContext();
  const { learners } = useParentContext();
  const [step, setStep] = useState<"intro" | "form" | "outro">(
    learners.length === 0
      ? "intro"
      : learners.length === parent?.subscriptionQuantity
      ? "outro"
      : "form"
  );

  const { data: subscriptionData, isLoading } = useDMQuery<{
    subscription: LearnerSubscriptionDetails;
  }>({
    path: "/payments/subscriptions/active",
    queryOptions: { refetchOnWindowFocus: false },
  });

  const numLearners = subscriptionData?.subscription.quantity ?? 0;

  if (isLoading) {
    return (
      <main className="flex h-dvh w-dvw items-center justify-center">
        <DmLoadingSpinner message="" />
      </main>
    );
  }

  return (
    <main className="flex h-dvh w-dvw flex-col items-center">
      <AddLearnersHeader
        title={step === "form" && numLearners > 1 ? "Add Learners" : undefined}
        showSkipLink={step === "form"}
      />

      {step === "intro" ? (
        <AddLearnersIntro
          numLearners={numLearners}
          isTrialing={subscriptionData?.subscription.status === "trialing"}
          next={() =>
            learners.length === numLearners ? setStep("outro") : setStep("form")
          }
        />
      ) : step === "form" ? (
        <AddLearnersForm
          numLearners={numLearners}
          next={() => setStep("outro")}
        />
      ) : (
        <AddLearnersOutro />
      )}
    </main>
  );
};
