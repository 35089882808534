export type CancelStep =
  | "multipleLearners"
  | "listFeatures"
  | "chooseReason"
  | "discount"
  | "confirmation";

export const CANCEL_REASON_CHOICES = {
  notRightFit: "Not the right fit",
  triedItOut: "Just wanted to try it out",
  missingCourse: "Missing the course I need",
  technicalIssues: "Experienced technical issues",
  noLongerNeed: "No longer need it",
  differentService: "Prefer a different service",
  tooExpensive: "Too expensive",
  other: "Other (Please explain)",
};

export type CancelReasonKey = keyof typeof CANCEL_REASON_CHOICES;

export interface CancelFeedbackFormData {
  reasons: CancelReasonKey[];
  missingCourseExplanation?: string;
  differentServiceExplanation?: string;
  otherExplanation?: string;
}
