import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import PasswordBox from "../BetaSignUp/PasswordBox";
import Button from "../../../student/components/generic/button";
import { useLogin } from "../../../utils/auth/login";

type Props = {
  setLoggedIn: (loggedIn: boolean) => void;
  updatePageState: (state: "forgot") => void;
};

const LoginForm = (props: Props) => {
  const queryClient = useQueryClient();
  const login = useLogin();

  const [loginLoading, setLoginLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    queryClient.invalidateQueries();
    queryClient.removeQueries();
    setLoginLoading(true);
    setError(undefined);
    (async () => {
      try {
        const { success } = await login(email, password, "learner");
        props.setLoggedIn(success);
        setLoginLoading(false);
        navigate(window.location.search);
      } catch (e: any) {
        props.setLoggedIn(false);
        setLoginLoading(false);
        setError(e.message);
      }
    })();
  };

  return (
    <>
      <div className="md:mt-16">
        <h2 className="mt-6 text-center font-[Lora] text-2xl font-bold text-dm-brand-blue-600">
          Log In
        </h2>
      </div>

      <div className="mt-2 flex-grow sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={submitForm}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-bold text-dm-charcoal-800"
              >
                Email or Username
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type={"text"}
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <PasswordBox
                label="Password"
                value={password}
                error={undefined}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="mt-4 w-full text-center">
              <button
                type="button"
                className="cursor-pointer py-1 text-dm-brand-blue-500 hover:text-dm-brand-blue-600 active:text-dm-brand-blue-800 disabled:opacity-70"
                disabled={loginLoading}
                onClick={() => props.updatePageState("forgot")}
              >
                Forgot Password
              </button>
            </div>

            <Button submit disabled={loginLoading} className="w-full">
              Log In
            </Button>
            {loginLoading && <div>Logging you in...</div>}
            {error && <div className="text-dm-error-500">{error}</div>}
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
