import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useState } from "react";
import clsx from "clsx";
import Button from "../../../student/components/generic/button";

export const ResendInviteLink: React.FC<{ learnerId: string }> = ({
  learnerId,
}) => {
  const toastContext = useDeltaToastContext();
  const [wasSent, setWasSent] = useState(false);

  const { mutateAsync: resendInviteMutation, isLoading } = useMutation(
    ({ learnerId }: { learnerId: string }) => {
      return axios.post<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/parent/resend-invite`,
        JSON.stringify({ learnerId }),
        withJsonHeader()
      );
    }
  );

  const resendInvite = async () => {
    try {
      await resendInviteMutation({ learnerId });
      setWasSent(true);
      setTimeout(() => {
        setWasSent(false);
      }, 3000);
    } catch (error: any) {
      toastContext.addToast({
        status: "Error",
        message:
          error?.response?.data?.message ||
          "There was an error resending the invite email. Please try again later.",
      });
    }
  };

  return (
    <Button
      type="link"
      className={clsx(
        "text-sm",
        wasSent && "pointer-events-none",
        isLoading && "pointer-events-none opacity-40"
      )}
      onClick={resendInvite}
    >
      {wasSent ? "Sent!" : "Resend Invite Link"}
    </Button>
  );
};
