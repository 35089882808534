import React, { useRef, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "./button";
import renderMathInElement from "../../utils/auto-render";
import { IconButton } from "./IconButton";
import clsx from "clsx";

type Props = {
  visible: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  header?: string;
  title?: string | React.ReactElement;
  body?: string | React.ReactElement;
  secondaryButtonText?: string;
  secondaryAriaLabel?: string;
  secondaryOnClick?: () => void;
  secondaryDisabled?: boolean;
  confirmButtonText?: string;
  confirmAriaLabel?: string;
  confirmDisabled?: boolean;
  noLine?: boolean;
  largeModal?: boolean;
  closeX?: boolean;
  renderMath?: boolean;
  footer?: React.ReactElement;
  bodyClassName?: string;
  widthClassName?: string;
};

const Modal = (props: Props) => {
  const confirmButtonRef = useRef(null);
  const messageRef = useRef(null);

  const showFooter =
    (props.secondaryButtonText && props.secondaryOnClick) || props.onConfirm;

  return (
    <Transition show={props.visible} as={Fragment}>
      <Dialog
        onClose={props.onClose}
        className="relative z-50"
        initialFocus={confirmButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className={`fixed inset-0 overflow-y-auto`}>
          <div
            className={`dm-modal-backdrop flex min-h-full items-center justify-center ${
              props.largeModal ? "" : "p-4"
            } text-center`}
          >
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom={`opacity-0 scale-95 ${
                props.largeModal ? "sm:w-[90%]" : ""
              }`}
              enterTo={`opacity-100 scale-100 ${
                props.largeModal ? "sm:w-[90%]" : ""
              }`}
              leave="ease-in duration-200"
              leaveFrom={`opacity-100 scale-100 ${
                props.largeModal ? "sm:w-[90%]" : ""
              }`}
              leaveTo={`opacity-0 scale-95 ${
                props.largeModal ? "sm:w-[90%]" : ""
              }`}
              beforeEnter={() => {
                if (props.renderMath) {
                  renderMathInElement(messageRef.current);
                }
              }}
              className={
                props.largeModal
                  ? "h-screen max-h-screen w-full p-4"
                  : props.widthClassName
                  ? props.widthClassName
                  : "w-full sm:w-[672px]"
              }
            >
              <Dialog.Panel
                className={clsx(
                  "relative transform overflow-hidden rounded-2xl bg-white shadow-xl transition-all",
                  props.largeModal
                    ? "mx-auto h-full w-full max-w-5xl"
                    : props.widthClassName
                    ? props.widthClassName
                    : "sm:w-[672px]"
                )}
              >
                {props.header && (
                  <div className="bg-dm-brand-blue-800 px-12 py-6 text-center">
                    <p className="text-white">{props.header}</p>
                  </div>
                )}
                {props.closeX && (
                  <IconButton
                    onClick={props.onClose}
                    className="absolute right-6 top-4"
                    aria-label="Close"
                    icon={
                      <i className="far fa-times text-2xl !text-dm-gray-500 sm:text-3xl" />
                    }
                  />
                )}
                <div
                  className={clsx(
                    "flex max-h-full flex-col justify-between px-6 py-4 text-left align-middle sm:px-12 sm:py-10",
                    props.largeModal ? "h-full" : "",
                    props.bodyClassName || ""
                  )}
                >
                  <div
                    className={clsx(
                      "flex flex-col gap-4 overflow-auto md:gap-6",
                      showFooter ? "mb-4 md:mb-10" : null
                    )}
                  >
                    {props.title && (
                      <Dialog.Title
                        as="h4"
                        className="mb-3 text-2xl font-medium leading-6 text-gray-900"
                      >
                        {props.title}
                      </Dialog.Title>
                    )}
                    {props.body && typeof props.body === "string" ? (
                      <Dialog.Description
                        className="text-base"
                        ref={messageRef}
                      >
                        {props.body}
                      </Dialog.Description>
                    ) : (
                      <div className="overflow-auto text-base" ref={messageRef}>
                        {props.body}
                      </div>
                    )}
                  </div>

                  {props.footer ? (
                    props.footer
                  ) : (
                    <div
                      className={clsx(
                        "mt-4 flex flex-col justify-between gap-4 sm:flex-row",
                        !props.noLine ? "border-t pt-8" : null,
                        !showFooter ? "hidden" : null
                      )}
                    >
                      {props.secondaryButtonText && props.secondaryOnClick ? (
                        <Button
                          onClick={props.secondaryOnClick}
                          type="outline"
                          size="small"
                          ariaLabel={props.secondaryAriaLabel}
                          disabled={props.secondaryDisabled}
                        >
                          {props.secondaryButtonText}
                        </Button>
                      ) : (
                        <div></div>
                      )}

                      {props.onConfirm && (
                        <Button
                          size="small"
                          onClick={props.onConfirm}
                          ref={confirmButtonRef}
                          ariaLabel={props.confirmAriaLabel}
                          disabled={props.confirmDisabled}
                        >
                          {props.confirmButtonText || "Yes"}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
