import { capitalize } from "lodash";
import { useDMQuery } from "../../../utils";
import {
  FormattedLearnerSubscriptionPlan,
  FormattedPrice,
  LearnerSubscriptionDetails,
} from "../../types";
import { useState } from "react";
import Button from "../../../student/components/generic/button";
import { useMutation } from "react-query";
import axios from "axios";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { deltamathAPI } from "../../../manager/utils";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useParentContext } from "../../contexts/ParentContext";
import { SubscriptionQuantityForm } from "./SubscriptionQuantityForm";
import { formatPrice } from "../../utils/formatPrice";
import { addYears } from "date-fns";
import { ProfileSection } from "./ProfileSection";

export const TempSubscriptionDebug: React.FC = () => {
  const toastContext = useDeltaToastContext();
  const { learners } = useParentContext();
  const [quantity, setQuantity] = useState<number>(1);
  const { learner } = useLearnerContext();

  const { data: plansData } = useDMQuery<{
    plans: FormattedLearnerSubscriptionPlan[];
  }>({
    path: "/payments/subscriptions/plans/list/published",
    queryOptions: { refetchOnWindowFocus: false },
  });

  const {
    data: subscriptionData,
    refetch: refetchSubscriptionData,
    isRefetching: isRefetchingSubscriptionData,
  } = useDMQuery<{
    subscription: LearnerSubscriptionDetails;
  }>({
    path: "/payments/subscriptions/active",
    queryOptions: { refetchOnWindowFocus: false },
  });

  const { mutateAsync: startSubscriptionSessionMutation } = useMutation<
    { data: { url: string } },
    unknown,
    string
  >((planId) => {
    const body = {
      planId,
      quantity,
      successUrl: window.location.origin + window.location.pathname,
    };
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/session/create`,
      JSON.stringify(body),
      withJsonHeader()
    );
  });

  const { mutateAsync: paymentMethodUpdateMutation } = useMutation<
    { data: { url: string } },
    unknown
  >(() => {
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/update/payment-method`,
      JSON.stringify({
        url: window.location.origin + window.location.pathname,
      }),
      withJsonHeader()
    );
  });

  const { mutateAsync: cancelSubscriptionMutation, isLoading: isCanceling } =
    useMutation((shouldCancel: boolean) => {
      return axios.post(
        `${deltamathAPI()}/payments/subscriptions/cancel/debug`,
        JSON.stringify({ shouldCancel }),
        withJsonHeader()
      );
    });

  const { mutateAsync: deleteDiscountMutation, isLoading: isDeletingDiscount } =
    useMutation((shouldCancel: boolean) => {
      return axios.post(
        `${deltamathAPI()}/payments/subscriptions/discount/debug`,
        JSON.stringify({ shouldCancel }),
        withJsonHeader()
      );
    });

  const { refetch: fetchSubscriptionUpgradePreviewData } = useDMQuery<{
    proratedAmount: FormattedPrice;
    fullAmount: FormattedPrice;
    netAmount: FormattedPrice;
  }>({
    path: "/payments/subscriptions/update/plan/preview",
    queryOptions: { refetchOnWindowFocus: false, enabled: false },
  });

  const { mutateAsync: subscriptionUpgradeMutation } = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/update/plan`,
      JSON.stringify({}),
      withJsonHeader()
    );
  });

  const startSubscription = async (planId: string) => {
    const { data } = await startSubscriptionSessionMutation(planId);
    window.location.href = data.url;
  };

  const cancelSubscription = async () => {
    try {
      await cancelSubscriptionMutation(
        !subscriptionData?.subscription.willBeCanceled
      );
      await refetchSubscriptionData();
    } catch (e: any) {
      toastContext.addToast({
        message: e.response.data.message || "Failed to cancel subscription",
        status: "Error",
      });
    }
  };

  const deleteDiscount = async () => {
    try {
      await deleteDiscountMutation(true);
      await refetchSubscriptionData();
    } catch (e: any) {
      toastContext.addToast({
        message: e.response.data.message || "Failed to delete discount",
        status: "Error",
      });
    }
  };

  const upgradeSubscriptionPreview = async () => {
    const { data } = await fetchSubscriptionUpgradePreviewData();
    if (!data) {
      return;
    }

    const positiveProratedAmount: FormattedPrice = {
      ...data.proratedAmount,
      value: Math.abs(data.proratedAmount.value),
    };
    const nextBillintDate = Intl.DateTimeFormat("en-us", {
      dateStyle: "medium",
    }).format(addYears(new Date(), 1));

    const message = [
      "You are about to switch to an annual subscription.",
      `You will be charged ${formatPrice(data.netAmount)} now`,
      `which includes a credit of ${formatPrice(
        positiveProratedAmount
      )} for the current period.`,
      `Your next payment will be ${formatPrice(
        data.fullAmount
      )} on ${nextBillintDate}.`,
      "Are you sure you want to continue?",
    ];

    if (window.confirm(message.join(" "))) {
      await subscriptionUpgradeMutation();
      await refetchSubscriptionData();
    }
  };

  const updatePaymentMethod = async () => {
    const { data } = await paymentMethodUpdateMutation();
    window.location.href = data.url;
  };

  const CancelButton = () =>
    subscriptionData?.subscription.status !== "canceled" ? (
      <Button
        type="link"
        onClick={cancelSubscription}
        disabled={isCanceling || isRefetchingSubscriptionData}
      >
        {subscriptionData?.subscription.willBeCanceled
          ? "Undo cancel"
          : "Cancel subscription"}
      </Button>
    ) : null;

  const UpgradeButton = () =>
    subscriptionData?.subscription.interval === "month" ? (
      <Button className="ml-4" type="link" onClick={upgradeSubscriptionPreview}>
        Switch to annual
      </Button>
    ) : null;

  const PaymentInfo = () => (
    <>
      Payment info: {capitalize(subscriptionData?.subscription.card.brand)},
      *****
      {subscriptionData?.subscription.card.last4}, exp{" "}
      {subscriptionData?.subscription.card.expMonth}/
      {subscriptionData?.subscription.card.expYear}
      <br />
      <Button type="link" onClick={updatePaymentMethod}>
        Change payment info
      </Button>
    </>
  );

  if (!learner?.entitlements.includes("billing")) {
    return (
      <ProfileSection title="Debug account mgmt">
        <div className="flex grow items-center justify-center">
          <p>
            You need the
            <code className="mx-[1px] rounded border border-gray-200 bg-gray-100 p-1 text-sm">
              billing
            </code>
            entitlement to view this feature
          </p>
        </div>
      </ProfileSection>
    );
  }

  return (
    <ProfileSection title="Debug account mgmt">
      <div>
        {subscriptionData?.subscription && (
          <div className="mb-4 flex flex-col gap-2 border-b border-b-dm-charcoal-100 pb-5">
            <h3 className="text-lg font-bold">Current subscription</h3>
            <h4 className="font-bold">
              {formatPrice(subscriptionData.subscription.amount)} per{" "}
              {subscriptionData.subscription.interval}
              {subscriptionData.subscription.quantity === 1
                ? " for 1 learner"
                : ` for ${subscriptionData.subscription.quantity} learners`}
            </h4>
            {subscriptionData.subscription.willBeCanceled ||
              (subscriptionData.subscription.status === "canceled" && (
                <div>
                  <span className="mr-2 text-red-700">Canceled.</span>
                  You will have access until{" "}
                  {Intl.DateTimeFormat("en-us", {
                    dateStyle: "medium",
                    timeStyle: "medium",
                  }).format(new Date(subscriptionData.subscription.periodEnd))}
                  <br />
                  <CancelButton />
                </div>
              ))}
            {subscriptionData.subscription.discount && (
              <div className="flex items-center gap-2">
                <b>Discount:</b>
                {subscriptionData.subscription.discount.percentOff}% off{" "}
                {subscriptionData.subscription.discount.numMonths === 1
                  ? "for 1 month"
                  : `for ${subscriptionData.subscription.discount.numMonths} months`}
                <Button
                  className=""
                  type="link"
                  onClick={deleteDiscount}
                  disabled={isDeletingDiscount}
                >
                  Delete discount
                </Button>
              </div>
            )}
            {subscriptionData.subscription.status === "trialing" && (
              <div>
                Free trial until{" "}
                {Intl.DateTimeFormat("en-us", {
                  dateStyle: "medium",
                  timeStyle: "medium",
                }).format(new Date(subscriptionData.subscription.periodEnd))}
                <br />
                <PaymentInfo />
                <br />
                <CancelButton />
                <p>
                  <em>No other plan changes during trial</em>
                </p>
              </div>
            )}

            <div>
              Next payment on{" "}
              {Intl.DateTimeFormat("en-us", {
                dateStyle: "medium",
                timeStyle: "medium",
              }).format(new Date(subscriptionData.subscription.periodEnd))}
              <br />
              <PaymentInfo />
              <br />
              <CancelButton />
              <UpgradeButton />
              <br />
              <SubscriptionQuantityForm
                currentQuantity={subscriptionData.subscription.quantity}
                refetchSubscriptionData={refetchSubscriptionData}
                subscription={subscriptionData.subscription}
              />
            </div>

            <hr className="mb-1 mt-4" />
            <h3 className="text-lg font-bold">Seats</h3>
            <ul>
              {learners.map((learner) => (
                <li key={learner._id}>
                  {learner.first}
                  {learner.entitlements.includes("learner") ? (
                    <span className="ml-2 rounded bg-green-600 px-2 py-1 text-xs text-white">
                      active
                    </span>
                  ) : (
                    <span className="ml-2 rounded bg-gray-600 px-2 py-1 text-xs text-white">
                      inactive
                    </span>
                  )}
                </li>
              ))}
              {learners.length < subscriptionData.subscription.quantity && (
                <li>
                  + {subscriptionData.subscription.quantity - learners.length}{" "}
                  unassigned seats
                </li>
              )}
            </ul>
          </div>
        )}

        <h3 className="text-lg font-bold">Plans</h3>
        <div className="flex items-center gap-2 self-start rounded bg-gray-100 px-3 py-2">
          <label htmlFor="quantity">Number of learners</label>
          <input
            className="rounded border border-dm-charcoal-100 px-2 py-1"
            id="quantity"
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
          />
        </div>
        <div className="flex gap-4">
          {plansData?.plans.map((plan) => (
            <div key={plan._id} className="flex flex-col gap-2">
              <h4 className="font-bold">{capitalize(plan.interval)}</h4>
              <pre className="bg-gray-100">{JSON.stringify(plan, null, 2)}</pre>
              <Button onClick={() => startSubscription(plan._id)}>
                Start {plan.interval} subscription
              </Button>
            </div>
          ))}
        </div>
      </div>
    </ProfileSection>
  );
};
