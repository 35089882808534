import { useMutation } from "react-query";
import Button from "../../../../student/components/generic/button";
import axios from "axios";
import { deltamathAPI } from "../../../../manager/utils";
import { withJsonHeader } from "../../../../shared/axiosUtils";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import { useState } from "react";

export const ResendInviteButton: React.FC<{ learnerId: string }> = ({
  learnerId,
}) => {
  const toastContext = useDeltaToastContext();
  const [wasSent, setWasSent] = useState(false);

  const { mutateAsync: resendInviteMutation, isLoading } = useMutation(
    ({ learnerId }: { learnerId: string }) => {
      return axios.post<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/parent/resend-invite`,
        JSON.stringify({ learnerId }),
        withJsonHeader()
      );
    }
  );

  const resendInvite = async () => {
    try {
      await resendInviteMutation({ learnerId });
      setWasSent(true);
      setTimeout(() => {
        setWasSent(false);
      }, 3000);
    } catch (error: any) {
      toastContext.addToast({
        status: "Error",
        message:
          error?.response?.data?.message ||
          "There was an error resending the invite email. Please try again.",
      });
    }
  };

  return (
    <Button
      className="group flex h-10 w-full items-center justify-center gap-2 text-sm"
      type="outline"
      onClick={resendInvite}
      isLoading={isLoading}
    >
      {wasSent ? (
        <>
          <i className="far fa-check !text-dm-charcoal-800 group-hover:!text-dm-brand-blue-600" />{" "}
          Sent!
        </>
      ) : (
        "Resend Invite Email"
      )}
    </Button>
  );
};
