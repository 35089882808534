import { format } from "date-fns";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useParentContext } from "../../contexts/ParentContext";
import { getLearnerAccountStatus } from "./getLearnerAccountStatus";
import { SubscriptionStatusPill } from "./SubscriptionStatusPill";
import { ResendInviteLink } from "./ResendInviteLink";
import { AddLearnerButton } from "./AddLearnerButton";
import { Skeleton } from "../Skeleton";
import { LearnerSubscriptionDetails } from "../../types";
import { EditAccountButton } from "./EditAccountButton";
import { ReactivateLink } from "./ReactivateLink";

export const AccountsTableWide: React.FC<{
  subscription: LearnerSubscriptionDetails;
}> = ({ subscription }) => {
  const { learner: parent } = useLearnerContext();
  const { learners } = useParentContext();

  const parentStatus = parent ? getLearnerAccountStatus(parent) : null;

  return (
    <div className="hidden w-full flex-col gap-8 lg:flex">
      <table className="w-full table-auto border-separate border-spacing-y-4 text-left text-sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Account Type</th>
            <th>User Name/Email</th>
            <th>Password</th>
            <th>Status</th>
            <th>Learner Start Date</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {parent?.first} {parent?.last}
            </td>
            <td>Parent</td>
            <td>{parent?.email}</td>
            <td>{"*".repeat(16)}</td>
            <td>
              {parentStatus && <SubscriptionStatusPill status={parentStatus} />}
            </td>
            <td>{parent && format(new Date(parent.createdAt), "MM/dd/yy")}</td>
            <td>{parent && <EditAccountButton user={parent} />}</td>
          </tr>
          {learners.map((learner) => {
            const status = getLearnerAccountStatus(learner);
            return (
              <tr key={learner._id}>
                <td>
                  {learner.first} {learner.last}
                </td>
                <td>Learner</td>
                <td>{learner.email}</td>
                <td>{status === "active" ? "*".repeat(16) : ""}</td>
                <td>
                  <SubscriptionStatusPill status={status} />
                </td>
                <td>
                  {status === "pending" ? (
                    <ResendInviteLink learnerId={learner._id} />
                  ) : status === "canceled" && parentStatus !== "canceled" ? (
                    <ReactivateLink
                      subscription={subscription}
                      learner={learner}
                    />
                  ) : (
                    format(new Date(learner.createdAt), "MM/dd/yy")
                  )}
                </td>
                <td>
                  <EditAccountButton user={learner} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {subscription ? (
        <AddLearnerButton
          className="flex h-10 items-center justify-center gap-2 p-0"
          subscription={subscription}
        />
      ) : (
        <div className="h-10 w-full">
          <Skeleton />
        </div>
      )}
    </div>
  );
};
