import { ProfileSection } from "./ProfileSection";
import { useParentContext } from "../../contexts/ParentContext";
import { ParentNav } from "../Parent/ParentNav";
import { REACT_APP_LEARNER_LINK, useDMQuery } from "../../../utils";
import { Learner, LearnerSubscriptionDetails } from "../../types";
import { NavLink } from "react-router-dom";
import { ProfileField } from "./ProfileField";
import { ProfilePage } from "./ProfilePage";
import { ProfileFieldGroup } from "./ProfileFieldGroup";
import { TempSubscriptionDebug } from "./TempSubscriptionDebug";
import { ProfileColumn } from "./ProfileColumn";
import { PaymentInformationField } from "./Fields/PaymentInformationField";
import { BillingHistoryField } from "./Fields/BillingHistoryField";
import { BillingFrequencyField } from "./Fields/BillingFrequencyField";
import { AccountsTable } from "./AccountsTable";
import { UpcomingChargesField } from "./Fields/UpcomingChargesField";
import { SubscriptionDetailsField } from "./Fields/SubscriptionDetailsField";
import { CancelSubscriptionModal } from "./Cancellation/CancelSubscriptionModal";
import { CancellationContextProvider } from "./Cancellation/CancellationContext";

export const ParentProfile: React.FC = () => {
  const { learners } = useParentContext();
  const jsonUser = localStorage.getItem("user");

  const { data: subscriptionData, refetch: refetchSubscriptionData } =
    useDMQuery<{
      subscription: LearnerSubscriptionDetails;
    }>({
      path: "/payments/subscriptions/active",
      queryOptions: { refetchOnWindowFocus: false },
    });

  // TODO: error handling for when/if user is null / no access to local storage?
  if (!jsonUser) {
    return null;
  }

  const user: Learner = JSON.parse(jsonUser);

  const numSeats = subscriptionData?.subscription.quantity ?? 0;
  const bannerText =
    numSeats === 1
      ? "Add your learner and link their profile to get started!"
      : "Add your learners and link their profiles to get started!";
  const bannerCta =
    numSeats === 1 ? "Invite learner now." : "Invite learners now.";

  return (
    <CancellationContextProvider
      refetchSubscriptionData={refetchSubscriptionData}
    >
      <div className="flex w-full flex-col">
        <ParentNav />
        <ProfilePage>
          {numSeats > learners.length && (
            <div className="w-[1288px] max-w-full rounded-lg border border-dm-warning-800 bg-dm-warning-100 p-4">
              <i className="far fa-exclamation-triangle mr-3 text-dm-warning-800" />
              <strong>Action Needed:</strong> {bannerText}{" "}
              <NavLink
                to={`${REACT_APP_LEARNER_LINK}/parent/add-learners`}
                className="font-bold text-dm-brand-blue-500 hover:underline"
              >
                {bannerCta}
              </NavLink>
            </div>
          )}
          <ProfileSection title="Account & Subscription Information">
            <ProfileColumn>
              <ProfileFieldGroup title="Your Account">
                <ProfileField label="Account Holder Name">
                  {user.first} {user.last}
                </ProfileField>
                <ProfileField label="Account Holder Email">
                  {user.email}
                </ProfileField>
                <ProfileField label="Password">{"*".repeat(16)}</ProfileField>
              </ProfileFieldGroup>

              <ProfileFieldGroup title="Your Subscription">
                <SubscriptionDetailsField
                  subscription={subscriptionData?.subscription}
                />
              </ProfileFieldGroup>
            </ProfileColumn>

            <ProfileColumn>
              <ProfileFieldGroup title="Billing">
                <UpcomingChargesField
                  subscription={subscriptionData?.subscription}
                />

                <BillingFrequencyField
                  subscription={subscriptionData?.subscription}
                />

                {subscriptionData?.subscription.status !== "trialing" && (
                  <BillingHistoryField
                    periodStart={subscriptionData?.subscription.periodStart}
                  />
                )}

                <PaymentInformationField
                  card={subscriptionData?.subscription.card}
                />
              </ProfileFieldGroup>
            </ProfileColumn>
          </ProfileSection>

          <ProfileSection title="Account Management">
            <AccountsTable subscription={subscriptionData?.subscription} />
          </ProfileSection>

          <TempSubscriptionDebug />
        </ProfilePage>
      </div>
      <CancelSubscriptionModal />
    </CancellationContextProvider>
  );
};
