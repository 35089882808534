import { useEffect } from "react";
import Button from "../../../../student/components/generic/button";
import { useCancellationContext } from "./CancellationContext";
import { CancelStepHeader } from "./CancelStepHeader";
import { useParentContext } from "../../../contexts/ParentContext";
import clsx from "clsx";

export const CancelMultipleLearners: React.FC = () => {
  const {
    selectedLearners,
    setSelectedLearners,
    setConfirmDisabled,
    learnerSelectRadioState,
    setLearnerSelectRadioState,
  } = useCancellationContext();
  const { learners } = useParentContext();

  const toggleSelectedLearner = (learnerId: string) => {
    if (selectedLearners.includes(learnerId)) {
      setSelectedLearners(selectedLearners.filter((id) => id !== learnerId));
    } else {
      setSelectedLearners([...selectedLearners, learnerId]);
    }
  };

  useEffect(() => {
    setConfirmDisabled(
      learnerSelectRadioState === null ||
        (learnerSelectRadioState === "some" && selectedLearners.length === 0)
    );
  }, [learnerSelectRadioState, selectedLearners.length, setConfirmDisabled]);

  return (
    <div className="flex flex-col gap-10">
      <CancelStepHeader title="Are You Canceling All Learner Subscriptions?" />
      <div className="flex flex-col items-center gap-4">
        <Button
          className={clsx(
            "w-48 rounded-full !px-0 text-sm",
            learnerSelectRadioState === "all" &&
              "pointer-events-none border-dm-brand-blue-800 bg-dm-brand-blue-800 text-white focus:text-white"
          )}
          type="outline"
          onClick={() => setLearnerSelectRadioState("all")}
        >
          Yes
        </Button>
        <Button
          className={clsx(
            "w-48 rounded-full !px-0 text-sm",
            learnerSelectRadioState === "some" &&
              "pointer-events-none border-dm-brand-blue-800 bg-dm-brand-blue-800 text-white focus:text-white"
          )}
          type="outline"
          onClick={() => setLearnerSelectRadioState("some")}
        >
          No, Only Some Learners
        </Button>
      </div>
      {learnerSelectRadioState === "some" && (
        <form
          className="flex flex-col gap-4"
          onSubmit={(e) => e.preventDefault()}
        >
          <h3 className="text-center font-serif font-bold text-dm-brand-blue-600 sm:text-lg">
            Check the learner accounts you&apos;d like to cancel
          </h3>
          <div className="flex flex-col gap-[10px] px-6">
            {learners.map((learner) => (
              <label key={learner._id} className="flex items-center gap-2">
                <input
                  className="focus:ring-transparent"
                  type="checkbox"
                  onChange={() => toggleSelectedLearner(learner._id)}
                  checked={selectedLearners.includes(learner._id)}
                />
                {learner.first} {learner.last}
              </label>
            ))}
          </div>
        </form>
      )}
    </div>
  );
};
