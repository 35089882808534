import { useMutation } from "react-query";
import Button from "../../../../student/components/generic/button";
import { useHomepageSignupContext } from "../HomepageSignupContext";
import { PricingConfigurator } from "./PricingConfigurator";
import axios from "axios";
import { deltamathAPI } from "../../../../manager/utils";
import { withJsonHeader } from "../../../../shared/axiosUtils";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";
import { Learner } from "../../../types";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";

export const Payment: React.FC = () => {
  const {
    plans,
    startTrialText,
    accountType,
    setAccountType,
    selectedPlan,
    numLearners,
  } = useHomepageSignupContext();
  const toastContext = useDeltaToastContext();

  const user: Learner | null = JSON.parse(
    localStorage.getItem("user") || "null"
  );

  const trialLength =
    plans && plans[0].trialLength > 0 ? plans[0].trialLength : undefined;
  const benefits = [
    "Build skills and gain confidence in math with personalized practice.",
    "Access all the learning tools you need in one convenient platform.",
    "Learn from high-quality content created by experienced math educators.",
    "Track your progress in real-time and celebrate your achievements.",
    "Prepare for success in school and beyond with unlimited course access.",
    trialLength
      ? `Enjoy a risk-free ${trialLength}-day trial and cancel anytime.`
      : "Enjoy a risk-free trial and cancel anytime.",
  ];

  const { mutateAsync: startSubscriptionSessionMutation } = useMutation<
    { data: { url: string } },
    unknown,
    void
  >(() => {
    const successUrl = new URL(window.location.href);
    successUrl.searchParams.delete("resume");
    const cancelUrl = new URL(window.location.href);
    cancelUrl.searchParams.set(
      "resume",
      encodeURIComponent(
        window.btoa(
          JSON.stringify({
            accountType: accountType || "",
            selectedPlan: selectedPlan?._id || "",
            numLearners: numLearners,
          })
        )
      )
    );
    const body = {
      planId: selectedPlan?._id,
      quantity: numLearners,
      successUrl: `${window.location.origin}${REACT_APP_LEARNER_LINK}/common/check-status`,
      cancelUrl: cancelUrl.href,
    };
    return axios.post(
      `${deltamathAPI()}/payments/subscriptions/session/create`,
      JSON.stringify(body),
      withJsonHeader()
    );
  });

  const startSubscription = async () => {
    try {
      const { data } = await startSubscriptionSessionMutation();
      window.location.href = data.url;
    } catch (e: any) {
      toastContext.addToast({
        message:
          e.response.data.message ||
          "Failed to start subscription. Please try again later.",
        status: "Error",
      });
    }
  };

  if (!plans) {
    return null;
  }

  return (
    <div className="px-4 pb-8 pt-14 sm:px-8 sm:pb-14 sm:pt-12">
      {!user && (
        <button
          className="group absolute left-4 top-4 p-1 text-xl text-dm-gray-200 hover:text-dm-gray-500 sm:left-6 sm:top-5"
          onClick={() => setAccountType(undefined)}
        >
          <i className="far fa-arrow-left" />
          <span className="sr-only">Back</span>
        </button>
      )}
      <h3 className="mb-8 text-center font-serif text-lg font-bold text-dm-brand-blue-600 sm:mb-10 sm:text-2xl">
        Your All-Access Pass to Math Success
      </h3>
      <div className="flex flex-col gap-10 sm:flex-row-reverse">
        <div className="sm:w-1/2">
          <h4 className="mb-4 hidden font-serif text-lg font-bold text-dm-brand-blue-600 sm:block">
            Tailor Your Subscription
          </h4>
          <div className="flex flex-col gap-6">
            <PricingConfigurator
              incrementorType="blue"
              accountType={accountType}
            />
            <Button className="w-full text-sm" onClick={startSubscription}>
              {startTrialText}
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-6 sm:w-1/2">
          <h3 className="font-serif text-lg font-bold text-dm-brand-blue-600">
            Benefits of Your Subscription
          </h3>
          <ul className="flex flex-col gap-4">
            {benefits.map((benefit) => (
              <li key={benefit} className="flex gap-[10px]">
                <i
                  className="far fa-check mt-1 !text-dm-brand-blue-500"
                  aria-hidden="true"
                ></i>
                <p>{benefit}</p>
              </li>
            ))}
          </ul>
          <Button className="sm:hidden" onClick={startSubscription}>
            {startTrialText}
          </Button>
        </div>
      </div>
    </div>
  );
};
