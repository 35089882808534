import Button from "../../../../student/components/generic/button";
import { getFilePath, REACT_APP_LEARNER_LINK } from "../../../../utils";
import { useParentContext } from "../../../contexts/ParentContext";
import { ResponsiveImage } from "../../ResponsiveImage";
import { ResendInviteButton } from "./ResendInviteButton";

export const AddLearnersOutro: React.FC = () => {
  const { learners } = useParentContext();

  const title =
    learners.length === 1
      ? learners[0].inviteCode
        ? "Learner Invite Sent!"
        : "Learner Added"
      : learners.some((l) => l.inviteCode)
      ? "Learners Invited!"
      : "Learners Added";

  const body =
    learners.length === 1
      ? learners[0].inviteCode
        ? `We've sent an invitation to ${learners[0].email}. They'll need to accept it to access/activate their DeltaMath for Home account.`
        : "Your learner has been added."
      : learners.some((l) => l.inviteCode)
      ? "We've sent invitations to your learners. They'll need to accept them to access/activate their DeltaMath for Home accounts."
      : "Your learners have been added.";

  return (
    <div className="flex w-full flex-col items-center gap-10 px-4">
      <div className="flex w-[424px] max-w-full flex-col text-center">
        <div className="flex justify-center">
          <ResponsiveImage
            className="mb-6 flex-none"
            srcs={[
              getFilePath("/images/learner/parent/deltie-stars.png"),
              getFilePath("/images/learner/parent/deltie-stars@2x.png"),
            ]}
            alt="Deltie seeing stars"
            aria-hidden
          />
        </div>
        <h1 className="mb-2 font-serif text-2xl font-bold text-dm-brand-blue-600">
          {title}
        </h1>
        <p>{body}</p>
      </div>
      <div className="flex w-[524px] max-w-full flex-col gap-6 sm:flex-row">
        {learners.every((l) => l.inviteCode !== undefined) && (
          <ResendInviteButton learnerId={learners[0]._id} />
        )}
        <Button
          className="flex h-10 w-full items-center justify-center gap-2 px-0 text-sm"
          href={`${REACT_APP_LEARNER_LINK}/parent`}
        >
          Explore Parent Dashboard <i className="far fa-arrow-right" />
        </Button>
      </div>
    </div>
  );
};
