import { format } from "date-fns";
import { ProfilePage } from "./ProfilePage";
import { ProfileSection } from "./ProfileSection";
import { ProfileField } from "./ProfileField";
import { ProfileFieldGroup } from "./ProfileFieldGroup";
import { Learner } from "../../types";
import { EditAccountButton } from "./EditAccountButton";
import { getLearnerAccountStatus } from "./getLearnerAccountStatus";
import { SubscriptionStatusPill } from "./SubscriptionStatusPill";
import Button from "../../../student/components/generic/button";

export const LearnerProfile: React.FC<{ user: Learner }> = ({ user }) => {
  const status = getLearnerAccountStatus(user);

  return (
    <ProfilePage withSidebar>
      <ProfileSection title="Account Information">
        <ProfileFieldGroup
          title="Your Account"
          className="relative sm:min-w-80"
        >
          <EditAccountButton className="absolute right-0 top-0" user={user} />
          <ProfileField label="Account Holder Name">
            {user.first} {user.last}
          </ProfileField>
          <ProfileField label="Account Holder Username/Email">
            {user.email}
          </ProfileField>
          <ProfileField label="Password">{"*".repeat(16)}</ProfileField>
          <ProfileField label="Account Type">Learner</ProfileField>
          <ProfileField label="Learner Start Date">
            {format(new Date(user.createdAt), "MM'/'dd'/'yy")}
          </ProfileField>
          <ProfileField label="Status">
            <SubscriptionStatusPill status={status} />
            {(status === "canceled" || status === "inactive") && (
              <Button
                className="mt-4"
                onClick={() =>
                  console.log(
                    "TODO: email parent / caregiver to activate account"
                  )
                }
              >
                Email Parent/Caregiver to Activate Account
              </Button>
            )}
          </ProfileField>
        </ProfileFieldGroup>
      </ProfileSection>
    </ProfilePage>
  );
};
