import TrashIcon from "../../../shared/icons/TrashIcon";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import DeltaMathConfirmation from "../../../shared/DeltaMathConfirmation";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import { DemoMode } from "../../../utils/demo";
import { REACT_APP_ADMIN_LINK, executeQuery } from "../../../utils";
import { IconButton } from "../../../student/components/generic/IconButton";

export default function CurrentAdmins({
  admins,
  school_id,
  partOfDistrict,
}: {
  admins: any;
  school_id: any;
  partOfDistrict: boolean;
}) {
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();
  const [primaryAdmin, setPrimaryAdmin] = useState<number>();

  useEffect(() => {
    if (admins.length > 0) {
      const primaryAdminIndex = admins.findIndex(
        (admin: any) => admin.account_type === "super_school"
      );
      setPrimaryAdmin(primaryAdminIndex);
    }
  }, [admins]);

  const [showDeleteAdminConfirmation, setShowDeleteAdminConfirmation] =
    useState(false);
  const [userToDelete, setUserToDelete] = useState({
    id: "",
    first: "",
    last: "",
  });

  const removeAdmin = useMutation(
    () => {
      return axios.delete(
        deltamathAPI() +
          `/admin_new/manage/remove/${userToDelete.id}/${school_id}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin-data");
        toastContext.addToast({
          status: "Success",
          message: "Admin Removed Succesfully",
        });
      },
      onError: () => {
        toastContext.addToast({
          status: "Error",
          message:
            "There was an error with the request. Please note that if you are a school level administator, you cannot remove a district level administrator.",
        });
      },
    }
  );

  const changePrimaryAdmin = useMutation(
    async (requestBody: {
      currentAdminId: string;
      newAdminId: string;
      schoolId: string;
    }) =>
      await executeQuery({
        path: "/admin_new/manage/update_school_admin",
        method: "post",
        requestBody,
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["admin-data"]);
        toastContext.addToast({
          status: res.success ? "Success" : "Error",
          message: res.message,
        });
      },
      onError: () => {
        toastContext.addToast({
          status: "Error",
          message: "There was an error with the request",
        });
      },
    }
  );

  useEffect(() => {
    setShowDeleteAdminConfirmation(false);
  }, [removeAdmin.isSuccess]);

  const columns = React.useMemo(() => {
    const columnsDefinition: {
      Header: string;
      accessor: string;
      align: string;
      Cell?: (props: any) => JSX.Element;
      disableSortBy?: boolean;
    }[] = [
      {
        Header: "First Name",
        accessor: "first",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_first" />;
        },
      },
      {
        Header: "Last Name",
        accessor: "last",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_last" />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_email" />;
        },
      },
    ];

    if (localStorage.getItem("customer_service_token")) {
      columnsDefinition.push(
        ...(!partOfDistrict
          ? [
              {
                Header: "Primary Admin?",
                accessor: "primary",
                align: "center",
                Cell: (props: any) => (
                  <input
                    type="radio"
                    value={props.row.index}
                    checked={primaryAdmin === props.row.index}
                    onChange={(e) => {
                      setPrimaryAdmin(parseInt(e.target.value, 10));
                      if (primaryAdmin !== undefined && primaryAdmin >= 0) {
                        changePrimaryAdmin.mutate({
                          currentAdminId: admins[primaryAdmin]?._id,
                          newAdminId: admins[parseInt(e.target.value)]?._id,
                          schoolId: school_id,
                        });
                      }
                    }}
                  />
                ),
              },
            ]
          : [])
      );
    }

    columnsDefinition.push({
      Header: "",
      accessor: "showDeleteButton",
      align: "",
      disableSortBy: true,
      Cell: (props: { row: { original: any } }) => (
        <div className="flex justify-end">
          {props.row.original.showDeleteButton && (
            <IconButton
              onClick={() => {
                setShowDeleteAdminConfirmation(true);
                setUserToDelete({
                  id: props.row.original._id,
                  first: props.row.original.first,
                  last: props.row.original.last,
                });
              }}
              icon={<TrashIcon />}
              aria-label={"Delete"}
            />
          )}
        </div>
      ),
    });
    return columnsDefinition;
  }, [admins, changePrimaryAdmin, partOfDistrict, primaryAdmin, school_id]);

  return (
    <>
      {admins && admins.length > 0 && (
        <div className="rounded-md border">
          <DeltaMathTable
            columns={columns}
            data={admins}
            options={{
              initialState: {
                sortBy: [
                  {
                    id: "last",
                    desc: false,
                  },
                ],
              },
            }}
          />
        </div>
      )}
      {admins && admins.length === 0 && (
        <>
          <p className="text-md my-1 mb-6 text-gray-500">No admins found.</p>

          <p>
            <Link
              className="underline hover:no-underline"
              to={`${REACT_APP_ADMIN_LINK}/schools/${school_id}/invite-admins`}
            >
              Invite an Admin
            </Link>{" "}
            to get started.
          </p>
        </>
      )}

      {showDeleteAdminConfirmation && userToDelete && (
        <DeltaMathConfirmation
          title={`Remove Admin`}
          message={
            <>
              Are you sure you want to remove{" "}
              <DemoMode value={userToDelete.first} type="person_first" />{" "}
              <DemoMode value={userToDelete.last} type="person_last" />
              &rsquo;s account? This admin will be removed from the school and
              will no longer have access to the school&apos;s data.
            </>
          }
          confirm="Delete"
          confirmAction={removeAdmin.mutate}
          cancel="Cancel"
          cancelAction={() => {
            setShowDeleteAdminConfirmation(false);
            setUserToDelete({
              id: "",
              first: "",
              last: "",
            });
          }}
        />
      )}
    </>
  );
}
