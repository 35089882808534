import { Course } from "../../types";
import { CourseCard } from "./CourseCard";
import { CoursesHeader } from "./CoursesHeader";

export const CoursesLanding: React.FC<{ courses: Course[] }> = ({
  courses,
}) => (
  <>
    <CoursesHeader />
    <div className="grid grid-cols-1 content-stretch justify-stretch gap-4 md:grid-cols-2">
      {courses.map((course) => (
        <CourseCard key={course.id} course={course} />
      ))}
    </div>
  </>
);
