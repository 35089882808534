import { useEffect } from "react";
import Modal from "../../../../student/components/generic/Modal";
import { AccountTypeChooser } from "./AccountTypeChooser";
import { SignupLanding } from "./SignupLanding";
import { useUserContext } from "../../../../shared/contexts/UserContext";
import { Learner } from "../../../types";
import { isSubscriptionActive } from "../../../utils/isSubscriptionActive";
import { useNavigate, useSearchParams } from "react-router-dom";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";
import { Payment } from "../Payment/Payment";
import { useHomepageSignupContext } from "../HomepageSignupContext";

export const SignupModal: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { showSignup, setShowSignup, accountType } = useHomepageSignupContext();

  const navigate = useNavigate();

  const userContext = useUserContext();
  const user: Learner | null = JSON.parse(
    localStorage.getItem("user") || "null"
  );
  const isLoggedIn = user?._id || userContext.getJWT().length > 0;

  const showPayment = isLoggedIn && (accountType || searchParams.has("paid"));

  useEffect(() => {
    if (user && isSubscriptionActive(user)) {
      if (user.entitlements.includes("learner")) {
        navigate(`${REACT_APP_LEARNER_LINK}/dashboard`);
      } else {
        navigate(`${REACT_APP_LEARNER_LINK}/parent`);
      }
    }
  }, [navigate, user]);

  return (
    <Modal
      visible={showSignup}
      onClose={() => setShowSignup(false)}
      closeX
      bodyClassName="!p-0"
      widthClassName={
        showPayment
          ? "max-w-full w-[1041px] transition-none"
          : "max-w-full w-[688px] transition-none"
      }
      body={
        showPayment ? (
          <Payment />
        ) : accountType === undefined ? (
          <AccountTypeChooser />
        ) : (
          <SignupLanding />
        )
      }
    />
  );
};
