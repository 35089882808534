import { useNavigate } from "react-router-dom";
import { executeQuery, REACT_APP_LEARNER_LINK } from "../../utils";
import { Learner } from "../types";
import { useEffect } from "react";
import { DmLoadingSpinner } from "../../manager/utils/functions";
import { useQuery } from "react-query";

export const CheckStatusPage: React.FC = () => {
  const navigate = useNavigate();

  const { refetch: fetchSessionStatus } = useQuery<{
    status: "waiting" | "success";
    user?: Learner;
  }>(
    "sessionStatus",
    () =>
      executeQuery({
        path: "/payments/subscriptions/session/status",
      }),
    { enabled: false }
  );

  useEffect(() => {
    let t: number;
    const checkSubscriptionStatus = async () => {
      const { data } = await fetchSessionStatus();
      if (data?.user && data?.status === "success") {
        localStorage.setItem("user", JSON.stringify(data.user));
        navigate(
          data.user.entitlements.includes("parent")
            ? `${REACT_APP_LEARNER_LINK}/parent/add-learners`
            : `${REACT_APP_LEARNER_LINK}/dashboard`
        );
      } else {
        t = window.setTimeout(() => {
          checkSubscriptionStatus();
        }, 1000);
      }
    };
    checkSubscriptionStatus();
    return () => clearTimeout(t);

    // Only run on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="flex h-dvh w-full items-center justify-center">
      <DmLoadingSpinner message="" />
    </main>
  );
};
