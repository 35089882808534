import { HTMLAttributes, useState } from "react";
import { IconButton } from "../../../student/components/generic/IconButton";
import { Learner } from "../../types";
import { EditAccountInformationModal } from "../Modals/EditAccountInformationModal";

export const EditAccountButton: React.FC<
  Omit<HTMLAttributes<HTMLButtonElement>, "onClick" | "aria-label"> & {
    user: Learner;
  }
> = ({ user, ...props }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <IconButton
        icon={<i className="fal fa-pencil text-dm-gray-200" />}
        aria-label={`Edit account information`}
        onClick={() => setShowModal(true)}
        {...props}
      />
      <EditAccountInformationModal
        user={user}
        visible={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};
